import Moment from 'moment';
import React, { Component } from "react";
import { Route } from "react-router-dom";
import { Link } from 'react-router-i18n';
import I18n from '../I18n';
import AuthService from "../services/auth.service";
import ProposerIssueMergeService from "../services/proposer.issue.merge.service";
import ProposerIssueService from "../services/proposer.issue.service";
import Footer from './footer';
import Header from './header';
import Loading from './loading';

export default class ProposerIssue extends Component {
	constructor(props) {
		super(props);
		if(AuthService.getCurrentUser() === null){
			window.location.href = '/';
		}

		this.state = {
			userId: AuthService.getCurrentUser().id,
			issueId: this.props.match.params.issueId,
			issue: null,
			adminName: null,
			issueProposals: null,
			mergeRequestProposers: null,
			mergeRequestHistoryProposers: null
		};
	}

	componentDidMount() {
		ProposerIssueService.getIssue(this.state.issueId).then(
			response => {
				this.setState({
					issue: response.data.message
				});
			},
			error => {
				this.setState({
					issue:
						(error.response &&
							error.response.data &&
							error.response.data.message) ||
						error.message ||
						error.toString()
				});
			}
		);

		ProposerIssueService.getIssueCreator(this.state.issueId).then(
			response => {
				this.setState({
					adminName: response.data.message
				});
			},
			error => {
				this.setState({
					adminName:
						(error.response &&
							error.response.data &&
							error.response.data.message) ||
						error.message ||
						error.toString()
				});
			}
		);

		ProposerIssueService.getIssueProposals(this.state.issueId, this.state.userId).then(
			response => {
				this.setState({
					issueProposals: response.data.message
				});
			},
			error => {
				this.setState({
					issueProposals:
						(error.response &&
							error.response.data &&
							error.response.data.message) ||
						error.message ||
						error.toString()
				});
			}
		);

		ProposerIssueMergeService.getMergeRequestProposers(this.state.userId, this.state.issueId).then(
			response => {
				this.setState({
					mergeRequestProposers: response.data.message
				});
			},
			error => {
				this.setState({
					mergeRequestProposers:
						(error.response &&
							error.response.data &&
							error.response.data.message) ||
						error.message ||
						error.toString()
				});
			}
		);

		ProposerIssueMergeService.getMergeRequestHistoryCreatorProposers(this.state.userId, this.state.issueId).then(
			response => {
				this.setState({
					mergeRequestHistoryProposers: response.data.message
				});
			},
			error => {
				this.setState({
					mergeRequestHistoryProposers:
						(error.response &&
							error.response.data &&
							error.response.data.message) ||
						error.message ||
						error.toString()
				});
			}
		);
	}

	canCreateProposal = () => {
		if (this.state.issue) {
			Moment.suppressDeprecationWarnings = true;
			const now = Moment().format('YYYY-MM-DD HH:mm:ss a');
			const minSP = this.state.issue ? Moment(this.state.issue[0].supportProposalPeriodFrom, 'YYYY-MM-DD HH:mm:ss a').format() : now;
			const maxSP = this.state.issue ? Moment(this.state.issue[0].supportProposalPeriodTo, 'YYYY-MM-DD HH:mm:ss a').format() : now;
			if (this.state.issue[0].nextRound === 0 && Moment().isBetween(minSP, maxSP, undefined, '[]')) {
				return true
			} else {
				return false
			}
		}
	}

	showCreateButton = () => {
		if (this.state.issueProposals) {
			var issueProposals = this.state.issueProposals
			issueProposals = Object.keys(issueProposals).filter((proposal) => {
				return issueProposals[proposal].user.indexOf(this.state.userId) > -1
			})
			return issueProposals.length === 0 ? true : false
		}
	}

	showMergeRequestButton = () => {
		if (this.state.mergeRequestProposers) {
			return this.state.mergeRequestProposers.length > 0 ? true : false
		}
	}

	showMergeRequestHistoryButton = () => {
		if (this.state.mergeRequestHistoryProposers) {
			return this.state.mergeRequestHistoryProposers.length > 0 ? true : false
		}
	}

	showResultButton = () => {
		if (this.state.issue) {
			Moment.suppressDeprecationWarnings = true;
			const now = Moment().format('YYYY-MM-DD HH:mm:ss a');
			const minV = this.state.issue ? Moment(this.state.issue[0].voteProposalPeriodFrom, 'YYYY-MM-DD HH:mm:ss a').format() : now;
			const maxV = this.state.issue ? Moment(this.state.issue[0].voteProposalPeriodTo, 'YYYY-MM-DD HH:mm:ss a').format() : now;
			if (Moment().isBetween(minV, maxV, undefined, '[]')) {
				return false
			} else {
				if (Moment().isBefore(maxV)) {
					return false
				} else {
					return true
				}
			}
		}
	}

	render() {
		const userId = this.state.userId;
		const issue = this.state.issue;
		const issueId = this.state.issueId;
		const creator = this.state.adminName;
		const issueProposals = this.state.issueProposals;
		return (
			<><Header />
				<div className="govgr-width-container">
					<div className="govgr-breadcrumbs">
						<ol className="govgr-breadcrumbs__list">
							<li className="govgr-breadcrumbs__list-item">
								<Route path={this.props.base} render={() =>
									<Link className="govgr-breadcrumbs__link" to="/proposer"><I18n t="main.Issues" /></Link>
								} />
							</li>
						</ol>
					</div>
					<div className="govgr-main-wrapper">
						<main className="govgr-grid-column-third-thirds">
							{
								issue ?
									Object.keys(issue).map(
										key => {
											return <h1 className="govgr-heading-l" key={key}>{issue[key].title}</h1>;
										}
									)
									:
									(
										<div className="govgr-warning-text">
											<span className="govgr-warning-text__icon">!</span>
											<strong className="govgr-warning-text__text">
												<span className="govgr-warning-text__assistive">Loading</span>
												Loading
											</strong>
										</div>
									)
							}
							{
								creator ? (<p className="govgr-body"><I18n t="main.Creator" />: {creator}</p>) : <Loading />
							}
							<h3><I18n t="main.IssueProposals" />
								{this.canCreateProposal() && this.showCreateButton() && (<span className="float-right"><Route path={this.props.base} render={() => <Link to={"/proposal/create/" + issueId} className="btn btn-primary"><I18n t="main.CreateProposal" /></Link>} /></span>)}
								{!this.showCreateButton() && (<span className="float-right"><Route path={this.props.base} render={() => <Link to={"/proposer/issue/merge/" + issueId} className="btn btn-success"><I18n t="main.MergeProposals" /></Link>} /></span>)}
								{this.showMergeRequestButton() && (<span className="float-right"><Route path={this.props.base} render={() => <Link to={"/proposer/issue/merge/request/" + issueId} className="btn btn-warning mr-2"><I18n t="main.MergeRequests" /></Link>} /></span>)}
								{this.showMergeRequestHistoryButton() && (<span className="float-right"><Route path={this.props.base} render={() => <Link to={"/proposer/issue/merge/request/history/" + issueId} className="btn btn-danger mr-2"><I18n t="main.MergeRequestsHistory" /></Link>} /></span>)}
								{this.showResultButton() && (<span className="float-right"><Route path={this.props.base} render={() => <Link to={"/result/issue/" + issueId} className="btn btn-secondary mr-2"><I18n t="main.Results" /></Link>} /></span>)}
							</h3>
							{
								issueProposals ?
									Object.keys(issueProposals).length > 0 ?
										Object.keys(issueProposals).map(
											key => {
												if (issueProposals[key].user.indexOf(userId) > -1) {
													return (
														<div className="card" style={{ backgroundColor: "#ffffff" }} key={key}>
															<div className="card-body">
																<div className="row">
																	<div className="col col-md-9">
																		<h5 className="card-title">
																			<Route path={this.props.base} render={() =>
																				<Link to={"/proposer/proposal/" + issueProposals[key]._id}>{issueProposals[key].title}</Link>
																			} />
																		</h5>
																	</div>
																</div>
															</div>
														</div>
													);
												} else {
													return (
														<div className="card" style={{ backgroundColor: "#ffffff" }} key={key}>
															<div className="card-body">
																<div className="row">
																	<div className="col col-md-9">
																		<h5 className="card-title">
																			{issueProposals[key].title}
																		</h5>
																	</div>
																</div>
															</div>
														</div>
													);
												}
											}
										)
										: <ul className="timeline mt-4"><I18n t="main.NoavailableIssueProposals" /></ul>
									: <Loading />
							}
						</main>
					</div>
				</div>
				<Footer /></>
		);
	}
}
