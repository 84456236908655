import axios from "axios";

const API_URL = "https://www.podsweb-backend.dynacomp2.eu/api/auth/";

class AuthService {
	login(username, password) {
		return axios
			.post(API_URL + "signin", {
				username,
				password
			})
			.then(response => {
				if (response.data.accessToken) {
					localStorage.setItem("user", JSON.stringify(response.data));
				}

				return response.data;
			});
	}

	logout() {
		localStorage.removeItem("user");
	}

	register(username, email, password, occupation, street, streetNumber, postalCode, city, roles) {
		return axios.post(API_URL + "signup", {
			username,
			email,
			password,
			occupation,
			street,
			streetNumber,
			postalCode,
			city,
			roles
		});
	}

	getCurrentUser() {
		return JSON.parse(localStorage.getItem('user'));
	}
}

export default new AuthService();
