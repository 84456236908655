import React, { Component } from 'react';

class Loading extends Component {
	render() {
		return (
			<div className="govgr-warning-text">
				<span className="govgr-warning-text__icon">!</span>
				<strong className="govgr-warning-text__text">
					<span className="govgr-warning-text__assistive">Loading</span>
					Loading
				</strong>
			</div>
		)
	}
}
export default Loading;