import Moment from 'moment';
import React, { Component } from "react";
import { Route } from "react-router-dom";
import { Link } from 'react-router-i18n';
import I18n from '../I18n';
import AuthService from "../services/auth.service";
import CitizenIssueService from "../services/citizen.issue.service";
import Footer from './footer';
import Header from './header';
import Loading from './loading';

export default class CitizenIssue extends Component {
	constructor(props) {
		super(props);
		if(AuthService.getCurrentUser() === null){
			window.location.href = '/';
		}

		this.state = {
			userId: AuthService.getCurrentUser().id,
			issueId: this.props.match.params.issueId,
			issue: null,
			adminName: null,
			issueProposals: null
		};
	}

	componentDidMount() {
		CitizenIssueService.getIssue(this.state.issueId).then(
			response => {
				this.setState({
					issue: response.data.message
				});
			},
			error => {
				this.setState({
					issue:
						(error.response &&
							error.response.data &&
							error.response.data.message) ||
						error.message ||
						error.toString()
				});
			}
		);

		CitizenIssueService.getIssueCreator(this.state.issueId).then(
			response => {
				this.setState({
					adminName: response.data.message
				});
			},
			error => {
				this.setState({
					adminName:
						(error.response &&
							error.response.data &&
							error.response.data.message) ||
						error.message ||
						error.toString()
				});
			}
		);

		CitizenIssueService.getIssueProposals(this.state.issueId).then(
			response => {
				this.setState({
					issueProposals: response.data.message
				});
			},
			error => {
				this.setState({
					issueProposals:
						(error.response &&
							error.response.data &&
							error.response.data.message) ||
						error.message ||
						error.toString()
				});
			}
		);
	}

	canCreateProposal = () => {
		if (this.state.issue) {
			Moment.suppressDeprecationWarnings = true;
			const now = Moment().format('YYYY-MM-DD HH:mm:ss a');
			const minSP = this.state.issue ? Moment(this.state.issue.supportProposalPeriodFrom, 'YYYY-MM-DD HH:mm:ss a').format() : now;
			const maxSP = this.state.issue ? Moment(this.state.issue.supportProposalPeriodTo, 'YYYY-MM-DD HH:mm:ss a').format() : now;
			if (this.state.issue.nextRound === 0 && Moment().isBetween(minSP, maxSP, undefined, '[]')) {
				return true
			} else {
				return false
			}
		}
	}

	showCreateButton = () => {
		if (this.state.issueProposals) {
			var issueProposals = this.state.issueProposals
			issueProposals = Object.keys(issueProposals).filter((proposal) => {
				return issueProposals[proposal].user[0] === this.state.userId
			})
			return issueProposals.length === 0 ? true : false
		}
	}

	showResultButton = () => {
		if (this.state.issue) {
			Moment.suppressDeprecationWarnings = true;
			const now = Moment().format('YYYY-MM-DD HH:mm:ss a');
			const minV = this.state.issue ? Moment(this.state.issue.voteProposalPeriodFrom, 'YYYY-MM-DD HH:mm:ss a').format() : now;
			const maxV = this.state.issue ? Moment(this.state.issue.voteProposalPeriodTo, 'YYYY-MM-DD HH:mm:ss a').format() : now;
			if (Moment().isBetween(minV, maxV, undefined, '[]')) {
				return false
			} else {
				if (Moment().isBefore(maxV)) {
					return false
				} else {
					return true
				}
			}
		}
	}

	render() {
		const issue = this.state.issue;
		const creator = this.state.adminName;
		const issueProposals = this.state.issueProposals;
		const issueId = this.state.issueId;
		return (
			<><Header />
				<div className="govgr-width-container">
					<div className="govgr-breadcrumbs">
						<ol className="govgr-breadcrumbs__list">
							<li className="govgr-breadcrumbs__list-item">
								<Route path={this.props.base} render={() =>
									<Link className="govgr-breadcrumbs__link" to="/citizen"><I18n t="main.Issues" /></Link>
								} />
							</li>
						</ol>
					</div>
					<div className="govgr-main-wrapper">
						<main className="govgr-grid-column-third-thirds">
							{
								issue ? (
									<h1 className="govgr-heading-l">{issue.title}</h1>
								)
									:
									(
										<div className="govgr-warning-text">
											<span className="govgr-warning-text__icon">!</span>
											<strong className="govgr-warning-text__text">
												<span className="govgr-warning-text__assistive">Loading</span>
												Loading
											</strong>
										</div>
									)
							}
							{
								creator ? (<p className="govgr-body"><I18n t="main.Creator" />: {creator}</p>) : <Loading />
							}
							<h3>
								<I18n t="main.IssueProposals" /> (<I18n t="main.Comment" />/<I18n t="main.Vote" />)
								{this.canCreateProposal() && this.showCreateButton() && (<span className="float-right"><a href={"/proposal/create/" + issueId} className="btn btn-primary"><I18n t="main.CreateProposal" /></a></span>)}
								{this.showResultButton() && (<span className="float-right"><a href={"/result/issue/" + issueId} className="btn btn-secondary mr-2"><I18n t="main.Results" /></a></span>)}
							</h3>
							{
								issueProposals ?
									Object.keys(issueProposals).length > 0 ?
										Object.keys(issueProposals).map(
											key => {
												return (
													<div className="card" style={{ backgroundColor: "#ffffff" }} key={key}>
														<div className="card-body">
															<div className="row">
																<div className="col col-md-9">
																	<h5 className="card-title">
																		<Route path={this.props.base} render={() =>
																			<Link to={"/proposal/" + issueProposals[key]._id}>{issueProposals[key].title}</Link>
																		} />
																	</h5>
																</div>
															</div>
														</div>
													</div>
												);
											}
										)
										: <ul className="timeline mt-4"><I18n t="main.NoavailableIssueProposals" /></ul>
									: <Loading />
							}
						</main>
					</div>
				</div>
				<Footer /></>
		);
	}
}
