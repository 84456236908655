import Moment from 'moment';
import React, { Component } from "react";
import { Route } from "react-router-dom";
import { Link } from 'react-router-i18n';
import I18n from '../I18n';
import AdminProposalCommentService from "../services/admin.proposal.comment.service";
import AuthService from "../services/auth.service";
import Footer from './footer';
import Header from './header';
import Loading from './loading';

export default class AdminProposalComment extends Component {
	constructor(props) {
		super(props);
		if(AuthService.getCurrentUser() === null){
			window.location.href = '/';
		}

		this.state = {
			userId: AuthService.getCurrentUser().id,
			proposalId: this.props.match.params.proposalId,
			proposal: null,
			proposerName: null,
			proposalComments: null,
			proposalHistory: null,
		};
	}

	componentDidMount() {
		AdminProposalCommentService.getProposal(this.state.proposalId).then(
			response => {
				this.setState({
					proposal: response.data.message
				});
			},
			error => {
				this.setState({
					proposal:
						(error.response &&
							error.response.data &&
							error.response.data.message) ||
						error.message ||
						error.toString()
				});
			}
		);

		AdminProposalCommentService.getProposalProposer(this.state.proposalId).then(
			response => {
				this.setState({
					proposerName: response.data.message
				});
			},
			error => {
				this.setState({
					proposerName:
						(error.response &&
							error.response.data &&
							error.response.data.message) ||
						error.message ||
						error.toString()
				});
			}
		);

		AdminProposalCommentService.getProposalComments(this.state.proposalId).then(
			response => {
				this.setState({
					proposalComments: response.data.message
				});
			},
			error => {
				this.setState({
					proposalComments:
						(error.response &&
							error.response.data &&
							error.response.data.message) ||
						error.message ||
						error.toString()
				});
			}
		);

		AdminProposalCommentService.getProposalHistory(this.state.proposalId).then(
			response => {
				this.setState({
					proposalHistory: response.data.message
				});
			},
			error => {
				this.setState({
					proposalHistory:
						(error.response &&
							error.response.data &&
							error.response.data.message) ||
						error.message ||
						error.toString()
				});
			}
		);
	}

	render() {
		const proposal = this.state.proposal;
		const proposer = this.state.proposerName;
		const proposalComments = this.state.proposalComments;
		const proposalHistory = this.state.proposalHistory;
		Moment.locale('el');
		return (
			<><Header />
				<div className="govgr-width-container">
					<div className="govgr-breadcrumbs">
						<ol className="govgr-breadcrumbs__list">
							<li className="govgr-breadcrumbs__list-item">
								<Route path={this.props.base} render={() =>
									<Link className="govgr-breadcrumbs__link" to="/admin"><I18n t="main.Issues" /></Link>
								} />
							</li>
							<li className="govgr-breadcrumbs__list-item">
								{
									proposal ?
										Object.keys(proposal).map(
											key => {
												return <Route key={key} path={this.props.base} render={() =>
													<Link key={key} className="govgr-breadcrumbs__link" to={"/admin/issue/" + proposal[key].issue[0]._id}>{proposal[key].issue[0].title}</Link>
												} />;
											}
										)
										:
										"Loading..."
								}
							</li>
						</ol>
					</div>
					<div className="govgr-main-wrapper">
						<main className="govgr-grid-column-third-thirds">
							{
								proposal ?
									Object.keys(proposal).map(
										key => {
											return <h1 className="govgr-heading-l" key={key}>{proposal[key].title}</h1>;
										}
									)
									:
									(
										<div className="govgr-warning-text">
											<span className="govgr-warning-text__icon">!</span>
											<strong className="govgr-warning-text__text">
												<span className="govgr-warning-text__assistive">Loading</span>
												Loading
											</strong>
										</div>
									)
							}
							{
								proposer ? (<p className="govgr-body"><I18n t="main.Proposer" />: {proposer}</p>) : <Loading />
							}
							<nav>
								<div className="nav nav-tabs" id="nav-tab" role="tablist">
									<button className="nav-link active" id="nav-tab1" data-bs-toggle="tab" data-bs-target="#tab1" type="button" role="tab" aria-controls="tab1" aria-selected="true"><I18n t="main.ProposalComments" /></button>
									<button className="nav-link" id="nav-tab2" data-bs-toggle="tab" data-bs-target="#tab2" type="button" role="tab" aria-controls="tab2" aria-selected="false"><I18n t="main.ProposalHistory" /></button>
								</div>
							</nav>
							<div className="tab-content" id="nav-tabContent">
								<div className="tab-pane fade show active" id="tab1" role="tabpanel" aria-labelledby="nav-tab1">
									<div>
										{
											proposalComments ?
												Object.keys(proposalComments).length > 0 ?
													Object.keys(proposalComments).map(
														key => {
															return (
																<div className="card" style={{ backgroundColor: "#ffffff" }} key={key}>
																	<div className="card-body">
																		<div className="row">
																			<div className="col col-md-9">
																				<h5 className="card-title">
																					{proposalComments[key].title}
																				</h5>
																			</div>
																			<div className="col col-md-3 border-start">
																				<p className="card-text">
																					{proposalComments[key].showComment ? <I18n t="main.Showtoothercitizens" /> : <I18n t="main.NotShowtoothercitizens" />}
																				</p>
																				<p className="card-text">
																					<I18n t="main.User" />: <b>{proposalComments[key].user[0].username}</b>, {proposalComments[key].user[0].email}
																				</p>
																			</div>
																		</div>

																	</div>
																</div>
															);
														}
													)
													: <ul className="timeline mt-4"><I18n t="main.NoavailableProposalComments" /></ul>
												: <ul className="timeline mt-4">Loading...</ul>
										}
									</div>
								</div>
								<div className="tab-pane fade" id="tab2" role="tabpanel" aria-labelledby="nav-tab2">
									<ul className="timeline mt-4">
										{
											proposalHistory ?
												Object.keys(proposalHistory).length > 0 ?
													Object.keys(proposalHistory).map(
														key => {
															var dt = new Date(proposalHistory[key].createdAt).toISOString().replace(/T/, ' ').replace(/\..+/, '');
															return <li key={key} className="event" data-date={Moment(dt).format('DD/MM/yyyy HH:mm:ss')}>
																<h3>{proposalHistory[key].title}</h3>
																<p><I18n t="main.PreviousTitle" />: {proposalHistory[key].oldTitle}</p>
																{
																	proposalHistory[key].proposalComments ? Object.keys(proposalHistory[key].proposalComments).map(
																		keys => {
																			return <p key={keys}><I18n t="main.Comment" />: {proposalHistory[key].proposalComments[keys].title}</p>;
																		}
																	) : "Loading..."
																}
															</li>;
														}
													)
													: <I18n t="main.NoavailableProposalHistory" />
												: "Loading..."
										}
									</ul>
								</div>
							</div>
						</main>
					</div>
				</div>
				<Footer />
			</>
		);
	}
}
