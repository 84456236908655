import axios from 'axios';
import authHeader from './auth-header';

const API_CITIZEN_URL = 'https://www.podsweb-backend.dynacomp2.eu/api/pods/citizen/issue/';

class CitizenIssueService {

	getIssue(issue) {
		return axios.get(API_CITIZEN_URL, {
			headers: authHeader(),
			params: {
				issue: issue
			},
		}
		);
	}

	getIssueCreator(issue) {
		return axios.get(API_CITIZEN_URL + "creator", {
			headers: authHeader(),
			params: {
				issue: issue
			},
		}
		);
	}

	getIssueProposals(issue) {
		return axios.get(API_CITIZEN_URL + "proposals", {
			headers: authHeader(),
			params: {
				issue: issue
			},
		}
		);
	}
}

export default new CitizenIssueService();
