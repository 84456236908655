import axios from 'axios';
import authHeader from './auth-header';

const API_CITIZEN_URL = 'https://www.podsweb-backend.dynacomp2.eu/api/pods/citizen/';

class CitizenSupportProposalService {

	create(userId, proposalId) {
		return axios.post(API_CITIZEN_URL + "proposal/support", {
			userId,
			proposalId
		},
			{ headers: authHeader() }
		);
	}

	getProposal(proposal) {
		return axios.get(API_CITIZEN_URL + "support/proposal", {
			headers: authHeader(),
			params: {
				proposal: proposal
			},
		}
		);

	}

}

export default new CitizenSupportProposalService();
