import axios from 'axios';
import authHeader from './auth-header';

const API_PROPOSER_URL = 'https://www.podsweb-backend.dynacomp2.eu/api/pods/proposer/issue/';
const API_CITIZEN_URL = 'https://www.podsweb-backend.dynacomp2.eu/api/pods/citizen/issue/';
const API_ADMIN_URL = 'https://www.podsweb-backend.dynacomp2.eu/api/pods/admin/issue/';

class IssueService {

	create(title, userId) {
		return axios.post(API_ADMIN_URL + "create", {
			title,
			userId
		},
			{ headers: authHeader() }
		);
	}

	getIssues() {
		return axios.get(API_CITIZEN_URL + "view/all",
			{ headers: authHeader() }
		);
	}

	getIssueProposals(issue) {
		return axios.get(API_CITIZEN_URL + 'view/all/proposals', {
			headers: authHeader(),
			params: {
				issue: issue
			},
		}
		);
	}

	getIssueDistinctUsers(issue) {
		return axios.get(API_CITIZEN_URL + 'view/all/distinct/users', {
			headers: authHeader(),
			params: {
				issue: issue
			},
		}
		);
	}

	getProposerIssues() {
		return axios.get(API_PROPOSER_URL + "view/all",
			{ headers: authHeader() }
		);
	}

	getProposerIssueProposals(issue) {
		return axios.get(API_PROPOSER_URL + 'view/all/proposals', {
			headers: authHeader(),
			params: {
				issue: issue
			},
		}
		);
	}

	getProposerIssueDistinctUsers(issue) {
		return axios.get(API_PROPOSER_URL + 'view/all/distinct/users', {
			headers: authHeader(),
			params: {
				issue: issue
			},
		}
		);
	}

	getAdminIssues() {
		return axios.get(API_ADMIN_URL + "view/all",
			{ headers: authHeader() }
		);
	}

	getAdminIssueProposals(issue) {
		return axios.get(API_ADMIN_URL + 'view/all/proposals', {
			headers: authHeader(),
			params: {
				issue: issue
			},
		}
		);
	}

	getAdminIssueDistinctUsers(issue) {
		return axios.get(API_ADMIN_URL + 'view/all/distinct/users', {
			headers: authHeader(),
			params: {
				issue: issue
			},
		}
		);
	}

}

export default new IssueService();
