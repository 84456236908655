import React, { Component } from "react";
import { Route } from "react-router-dom";
import { Link } from 'react-router-i18n';
import CheckButton from "react-validation/build/button";
import Form from "react-validation/build/form";
import Textarea from "react-validation/build/textarea";
import I18n from '../I18n';
import AuthService from "../services/auth.service";
import ProposalService from "../services/proposal.service";
import ProposerIssueService from "../services/proposer.issue.service";
import Footer from './footer';
import Header from './header';

const required = value => {
	if (!value) {
		return (
			<p className="govgr-error-message">
				<I18n t="main.Thisfieldisrequired" />
			</p>
		);
	}
};

const vtitle = value => {
	if (value.length < 3) {
		return (
			<div className="alert alert-danger" role="alert">
				<I18n t="main.Thetitlemustbegreaterthan3characters" />
			</div>
		);
	}
};

export default class ProposalCreate extends Component {
	constructor(props) {
		super(props);
		if(AuthService.getCurrentUser() === null){
			window.location.href = '/';
		}
		this.handleCreate = this.handleCreate.bind(this);
		this.onChangeTitle = this.onChangeTitle.bind(this);

		this.state = {
			currentUser: AuthService.getCurrentUser(),
			title: "",
			issueId: this.props.match.params.issueId,
			issue: null,
			userId: AuthService.getCurrentUser().id,
			successful: false,
			message: ""
		};
	}

	componentDidMount() {
		ProposerIssueService.getIssue(this.state.issueId).then(
			response => {
				this.setState({
					issue: response.data.message
				});
			},
			error => {
				this.setState({
					issue:
						(error.response &&
							error.response.data &&
							error.response.data.message) ||
						error.message ||
						error.toString()
				});
			}
		);
	}

	onChangeTitle(e) {
		this.setState({
			title: e.target.value
		});
	}

	handleCreate(e) {
		e.preventDefault();

		this.setState({
			message: "",
			successful: false
		});

		this.form.validateAll();

		if (this.checkBtn.context._errors.length === 0) {
			ProposalService.create(
				this.state.title,
				this.state.issueId,
				this.state.userId
			).then(
				response => {
					this.setState({
						message: response.data.message,
						successful: true
					});
					var item = JSON.parse(localStorage.getItem("user"));
					if (!item.roles.includes("ROLE_PROPOSER")) {
						item.roles.push("ROLE_PROPOSER");
					}
					localStorage.removeItem("user");
					localStorage.setItem("user", JSON.stringify(item));
					window.location.href = '/issue/' + this.state.issueId;
					// window.location.reload(false);
				},
				error => {
					const resMessage =
						(error.response &&
							error.response.data &&
							error.response.data.message) ||
						error.message ||
						error.toString();

					this.setState({
						successful: false,
						message: resMessage
					});
				}
			);
		}
	}

	render() {
		const issue = this.state.issue;
		return (
			<>
				<Header />
				<div className="govgr-width-container">
					<div className="govgr-breadcrumbs">
						<ol className="govgr-breadcrumbs__list">
							<li className="govgr-breadcrumbs__list-item">
								<Route path={this.props.base} render={() =>
									<Link className="govgr-breadcrumbs__link" to={this.state.currentUser.roles.includes("ROLE_ADMIN") ? '/admin' : (this.state.currentUser.roles.includes("ROLE_PROPOSER") ? '/proposer' : '/citizen')}><I18n t="main.Issues" /></Link>
								} />
							</li>
							<li className="govgr-breadcrumbs__list-item">
								{
									issue ?
										<Route path={this.props.base} render={() =>
											<Link className="govgr-breadcrumbs__link" to={(this.state.currentUser.roles.includes("ROLE_ADMIN") ? '/admin' : (this.state.currentUser.roles.includes("ROLE_PROPOSER") ? '/proposer' : '')) + "/issue/" + issue[0]._id}>{issue[0].title}</Link>
										} />
										: "Loading..."
								}
							</li>
						</ol>
					</div>
					<div className="govgr-main-wrapper">
						<main className="govgr-grid-column-third-thirds">
							<Form
								onSubmit={this.handleCreate}
								ref={c => {
									this.form = c;
								}}
							>
								{!this.state.successful && (
									<div>
										<div className="govgr-field">
											<label className="govgr-label">
												<span role="heading" aria-level="1" className="govgr-heading-m"><I18n t="main.TitleofProposalandDescription" /></span>
												<p className="govgr-hint"><I18n t="main.Settingupanewproposal" /></p>
												<Textarea
													className="govgr-textarea"
													rows="5"
													name="title"
													value={this.state.title}
													onChange={this.onChangeTitle}
													validations={[required, vtitle]}
												/>
											</label>
										</div>

										<div className="form-group">
											<button className="btn btn-primary btn-block"><I18n t="main.CreateProposal" /></button>
										</div>
									</div>
								)}

								{this.state.message && (
									<div className="form-group">
										<div
											className={
												this.state.successful
													? "alert alert-success"
													: "alert alert-danger"
											}
											role="alert"
										>
											{this.state.message}
										</div>
									</div>
								)}
								<CheckButton
									style={{ display: "none" }}
									ref={c => {
										this.checkBtn = c;
									}}
								/>
							</Form>
						</main>
					</div>
				</div>
				<Footer />
			</>
		);
	}
}
