import React, { Component } from "react";
import { Route } from "react-router-dom";
import { Link } from 'react-router-i18n';
import I18n from '../I18n';
import AuthService from "../services/auth.service";
import UserService from "../services/user.service";
import Footer from './footer';
import Header from './header';

export default class Home extends Component {
	constructor(props) {
		super(props);

		this.state = {
			content: ""
		};
	}

	componentDidMount() {
		UserService.getPublicContent().then(
			response => {
				this.setState({
					content: response.data
				});
			},
			error => {
				this.setState({
					content:
						(error.response && error.response.data) ||
						error.message ||
						error.toString()
				});
			}
		);
	}

	render() {
		return (
			<>
				<Header />
				<div className="govgr-width-container">
					<div className="govgr-main-wrapper">
						<main className="govgr-grid-column-two-thirds">
							<h1 className="govgr-heading-xl"><I18n t="home.title" /></h1>
							<p className="govgr-body"><I18n t="home.podDescription" /></p>
							{!AuthService.getCurrentUser() && (
								<>
									<Route path={this.props.base} render={() =>
										<Link to={"/login"} className="govgr-btn govgr-btn-primary govgr-btn-cta">
											<I18n t="home.Login" />
											<svg viewBox="0 0 24 24" className="govgr-arrow--right" focusable="false" aria-hidden="true"> <path d="M8.5,2L6.1,4.3l7.6,7.7l-7.6,7.6L8.5,22l10-10L8.5,2z" /> </svg>
										</Link>
									} />
									<Route path={this.props.base} render={() =>
										<Link to={"/register"} className="govgr-btn govgr-btn-primary govgr-btn-cta">
											<I18n t="home.signUp" />
											<svg viewBox="0 0 24 24" className="govgr-arrow--right" focusable="false" aria-hidden="true"> <path d="M8.5,2L6.1,4.3l7.6,7.7l-7.6,7.6L8.5,22l10-10L8.5,2z" /> </svg>
										</Link>
									} />
								</>
							)}
						</main>
						<aside className="govgr-grid-column-one-third">
							<img src="assets/img/hero-img.png" className="img-fluid" alt="" />
						</aside>
					</div>
				</div>
				<Footer />
			</>
		);
	}
}
