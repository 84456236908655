import Moment from 'moment';
import React, { Component } from "react";
import { Route } from "react-router-dom";
import { Link } from 'react-router-i18n';
import I18n from '../I18n';
import AuthService from "../services/auth.service";
import IssueService from "../services/issue.service";
import UserService from "../services/user.service";
import Footer from './footer';
import Header from './header';
import Loading from './loading';

export default class BoardProposer extends Component {
	constructor(props) {
		super(props);
		if(AuthService.getCurrentUser() === null){
			window.location.href = '/';
		}

		this.state = {
			content: "",
			issues: [],
			issueProposals: [],
			issueDistinctUsers: [],
			userId: AuthService.getCurrentUser().id
		};
	}

	componentDidMount() {
		UserService.getProposerBoard().then(
			response => {
				this.setState({
					content: response.data
				});
			},
			error => {
				this.setState({
					content:
						(error.response &&
							error.response.data &&
							error.response.data.message) ||
						error.message ||
						error.toString()
				});
			}
		);

		IssueService.getProposerIssues().then(
			response => {
				this.setState({
					issues: [...this.state.issues, response.data.message]
				});
				Object.values(this.state.issues).forEach((issues) => {
					Object.values(issues).forEach((issue) => {
						IssueService.getProposerIssueProposals(issue._id).then(
							response => {
								this.setState({
									issueProposals: [...this.state.issueProposals, response.data.message]
								});
							},
							error => {
								this.setState({
									issueProposals: [
										(error.response &&
											error.response.data &&
											error.response.data.message) ||
										error.message ||
										error.toString()
									]
								});
							}
						);
						IssueService.getProposerIssueDistinctUsers(issue._id).then(
							response => {
								this.setState({
									issueDistinctUsers: [...this.state.issueDistinctUsers, response.data.message]
								});
							},
							error => {
								this.setState({
									issueDistinctUsers: [
										(error.response &&
											error.response.data &&
											error.response.data.message) ||
										error.message ||
										error.toString()
									]
								});
							}
						);
					});
				});
			},
			error => {
				this.setState({
					issues: [
						(error.response &&
							error.response.data &&
							error.response.data.message) ||
						error.message ||
						error.toString()
					]
				});
			}
		);
	}

	render() {
		Moment.suppressDeprecationWarnings = true;
		const items = this.state.issues[0];
		const issueProposals = [];
		this.state.issueProposals.forEach((issue) => {
			issueProposals[Object.keys(issue)] = Object.values(Object.values(issue)[0]);
		});
		const issueDistinctUsers = [];
		this.state.issueDistinctUsers.forEach((issue) => {
			issueDistinctUsers[Object.keys(issue)] = Object.values(Object.values(issue)[0]);
		});
		let runningIssues = null;
		let closedIssues = null;
		let futureIssues = null;
		if (items) {
			runningIssues = Object.entries(items).filter(issue => {
				const minIssueDateTime = Moment(issue[1].supportIssuePeriodFrom, 'YYYY-MM-DD HH:mm:ss a').format();
				const maxIssueDateTime = Moment(issue[1].voteProposalPeriodTo, 'YYYY-MM-DD HH:mm:ss a').format();
				return Moment().isBetween(minIssueDateTime, maxIssueDateTime, undefined, '[]');
			});
			closedIssues = Object.entries(items).filter(issue => {
				const now = Moment().format('YYYY-MM-DD HH:mm:ss a');
				const maxIssueDateTime = issue[1].voteProposalPeriodTo ? Moment(issue[1].voteProposalPeriodTo, 'YYYY-MM-DD HH:mm:ss a').format() : now;
				return Moment().isAfter(maxIssueDateTime);
			});
			futureIssues = Object.entries(items).filter(issue => {
				const now = Moment().format('YYYY-MM-DD HH:mm:ss a');
				const minIssueDateTime = issue[1].supportIssuePeriodFrom ? Moment(issue[1].supportIssuePeriodFrom, 'YYYY-MM-DD HH:mm:ss a').format() : now;
				return Moment().isBefore(minIssueDateTime);
			});
		}
		return (
			<><Header />
				<div className="govgr-width-container">
					<div className="govgr-main-wrapper">
						<main className="govgr-grid-column-third-thirds">
							<h1 className="govgr-heading-l"><I18n t="main.Issues" /></h1>
							<span className="govgr-caption-xl"><I18n t="main.Issuesfordeliberation" /></span>
							<nav>
								<div className="nav nav-tabs" id="nav-tab" role="tablist">
									<button className="nav-link active" id="nav-tab1" data-bs-toggle="tab" data-bs-target="#tab1" type="button" role="tab" aria-controls="tab1" aria-selected="true"><I18n t="main.RunningIssues" /></button>
									<button className="nav-link" id="nav-tab2" data-bs-toggle="tab" data-bs-target="#tab2" type="button" role="tab" aria-controls="tab2" aria-selected="false"><I18n t="main.ClosedIssues" /></button>
									<button className="nav-link" id="nav-tab3" data-bs-toggle="tab" data-bs-target="#tab3" type="button" role="tab" aria-controls="tab3" aria-selected="false"><I18n t="main.FutureIssues" /></button>
								</div>
							</nav>
							<div className="tab-content" id="nav-tabContent">
								<div className="tab-pane fade show active" id="tab1" role="tabpanel" aria-labelledby="nav-tab1">
									<div>
										{
											runningIssues ?
												runningIssues.length > 0 ?
													runningIssues.map(
														issue => {
															const tags = [];

															for (let i = 0; i < issue[1].tags.length; i++) {
																tags.push(<span className="badge rounded-pill bg-info mr-2" key={i}>{issue[1].tags[i].label}</span>);
															}
															const now = Moment().format('YYYY-MM-DD HH:mm:ss a');
															const minSI = issue[1].supportIssuePeriodFrom ? Moment(issue[1].supportIssuePeriodFrom, 'YYYY-MM-DD HH:mm:ss a').format() : now;
															const maxSI = issue[1].supportIssuePeriodTo ? Moment(issue[1].supportIssuePeriodTo, 'YYYY-MM-DD HH:mm:ss a').format() : now;
															const minSP = issue[1].supportProposalPeriodFrom ? Moment(issue[1].supportProposalPeriodFrom, 'YYYY-MM-DD HH:mm:ss a').format() : now;
															const maxSP = issue[1].supportProposalPeriodTo ? Moment(issue[1].supportProposalPeriodTo, 'YYYY-MM-DD HH:mm:ss a').format() : now;
															const minC = issue[1].commentProposalPeriodFrom ? Moment(issue[1].commentProposalPeriodFrom, 'YYYY-MM-DD HH:mm:ss a').format() : now;
															const maxC = issue[1].commentProposalPeriodTo ? Moment(issue[1].commentProposalPeriodTo, 'YYYY-MM-DD HH:mm:ss a').format() : now;
															const minV = issue[1].voteProposalPeriodFrom ? Moment(issue[1].voteProposalPeriodFrom, 'YYYY-MM-DD HH:mm:ss a').format() : now;
															const maxV = issue[1].voteProposalPeriodTo ? Moment(issue[1].voteProposalPeriodTo, 'YYYY-MM-DD HH:mm:ss a').format() : now;

															return (
																<div className="card" style={{ backgroundColor: "#ffffff" }} key={issue[1]._id}>
																	<div className="card-body">
																		<div className="row">
																			<div className="col col-md-9">
																				<h5 className="card-title">
																					<Route path={this.props.base} render={() =>
																						<Link to={"/proposer/issue/" + issue[1]._id}>{issue[1].title}</Link>
																					} />
																				</h5>
																				{issue[1].description && <p className="card-text"><I18n t="main.IssueDescription" />: {issue[1].description}</p>}
																				{tags}
																			</div>
																			<div className="col col-md-3 border-start">
																				<p className="card-text">
																					{issue[1].supportIssue != null ? issue[1].supportIssue : ''}
																					{issue[1].supportIssue != null ? <I18n t="main.supportsneeded" /> : ''}
																				</p>
																				{Moment().isBetween(minSI, maxSI, undefined, '[]') && <p className="card-text"><I18n t="main.PhaseSupportingIssue" /> ({Moment(minSI).format('DD/MM/YYYY HH:mm') + ' - ' + Moment(maxSI).format('DD/MM/YYYY HH:mm')})</p>}
																				{Moment().isBetween(minSP, maxSP, undefined, '[]') && <p className="card-text"><I18n t="main.PhaseSupportingProposals" /> ({Moment(minSP).format('DD/MM/YYYY HH:mm') + ' - ' + Moment(maxSP).format('DD/MM/YYYY HH:mm')})</p>}
																				{Moment().isBetween(minC, maxC, undefined, '[]') && <p className="card-text"><I18n t="main.PhaseCommentProposals" /> ({Moment(minC).format('DD/MM/YYYY HH:mm') + ' - ' + Moment(maxC).format('DD/MM/YYYY HH:mm')})</p>}
																				{Moment().isBetween(minV, maxV, undefined, '[]') && <p className="card-text"><I18n t="main.PhaseVoteProposals" /> ({Moment(minV).format('DD/MM/YYYY HH:mm') + ' - ' + Moment(maxV).format('DD/MM/YYYY HH:mm')})</p>}
																				{Moment().isAfter(maxV) && <p className="card-text"><I18n t="main.PhaseResults" /></p>}
																			</div>
																		</div>
																	</div>
																</div>
															);
														}
													)
													: <ul className="timeline mt-4"><I18n t="main.NoavailableRunningIssues" /></ul>
												:
												<Loading />
										}
									</div>
								</div>
								<div className="tab-pane fade" id="tab2" role="tabpanel" aria-labelledby="nav-tab2">
									<div>
										{
											closedIssues ?
												closedIssues.length > 0 ?
													closedIssues.map(
														issue => {
															const tags = [];

															for (let i = 0; i < issue[1].tags.length; i++) {
																tags.push(<span className="badge rounded-pill bg-info mr-2" key={i}>{issue[1].tags[i].label}</span>);
															}
															const now = Moment().format('YYYY-MM-DD HH:mm:ss a');
															const minSI = issue[1].supportIssuePeriodFrom ? Moment(issue[1].supportIssuePeriodFrom, 'YYYY-MM-DD HH:mm:ss a').format() : now;
															const maxSI = issue[1].supportIssuePeriodTo ? Moment(issue[1].supportIssuePeriodTo, 'YYYY-MM-DD HH:mm:ss a').format() : now;
															const minSP = issue[1].supportProposalPeriodFrom ? Moment(issue[1].supportProposalPeriodFrom, 'YYYY-MM-DD HH:mm:ss a').format() : now;
															const maxSP = issue[1].supportProposalPeriodTo ? Moment(issue[1].supportProposalPeriodTo, 'YYYY-MM-DD HH:mm:ss a').format() : now;
															const minC = issue[1].commentProposalPeriodFrom ? Moment(issue[1].commentProposalPeriodFrom, 'YYYY-MM-DD HH:mm:ss a').format() : now;
															const maxC = issue[1].commentProposalPeriodTo ? Moment(issue[1].commentProposalPeriodTo, 'YYYY-MM-DD HH:mm:ss a').format() : now;
															const minV = issue[1].voteProposalPeriodFrom ? Moment(issue[1].voteProposalPeriodFrom, 'YYYY-MM-DD HH:mm:ss a').format() : now;
															const maxV = issue[1].voteProposalPeriodTo ? Moment(issue[1].voteProposalPeriodTo, 'YYYY-MM-DD HH:mm:ss a').format() : now;

															return (
																<div className="card" style={{ backgroundColor: "#ffffff" }} key={issue[1]._id}>
																	<div className="card-body">
																		<div className="row">
																			<div className="col col-md-9">
																				<h5 className="card-title">
																					<Route path={this.props.base} render={() =>
																						<Link to={"/proposer/issue/" + issue[1]._id}>{issue[1].title}</Link>
																					} />
																				</h5>
																				{issue[1].description && <p className="card-text"><I18n t="main.IssueDescription" />: {issue[1].description}</p>}
																				{issueProposals[issue[1]._id + '_proposals'] && <p className="card-text"><I18n t="main.FinalActiveProposals" />: {issueProposals[issue[1]._id + '_proposals'].length}</p>}
																				{issueDistinctUsers[issue[1]._id + '_distinctUsers'] && <p className="card-text"><I18n t="main.FinalParticipants" />: {issueDistinctUsers[issue[1]._id + '_distinctUsers'].length}</p>}
																				{tags}
																			</div>
																			<div className="col col-md-3 border-start">
																				<p className="card-text">
																					{issue[1].supportIssue != null ? issue[1].supportIssue : ''}
																					{issue[1].supportIssue != null ? <I18n t="main.supportsneeded" /> : ''}
																				</p>
																				{Moment().isBetween(minSI, maxSI, undefined, '[]') && <p className="card-text"><I18n t="main.PhaseSupportingIssue" /> ({Moment(minSI).format('DD/MM/YYYY HH:mm') + ' - ' + Moment(maxSI).format('DD/MM/YYYY HH:mm')})</p>}
																				{Moment().isBetween(minSP, maxSP, undefined, '[]') && <p className="card-text"><I18n t="main.PhaseSupportingProposals" /> ({Moment(minSP).format('DD/MM/YYYY HH:mm') + ' - ' + Moment(maxSP).format('DD/MM/YYYY HH:mm')})</p>}
																				{Moment().isBetween(minC, maxC, undefined, '[]') && <p className="card-text"><I18n t="main.PhaseCommentProposals" /> ({Moment(minC).format('DD/MM/YYYY HH:mm') + ' - ' + Moment(maxC).format('DD/MM/YYYY HH:mm')})</p>}
																				{Moment().isBetween(minV, maxV, undefined, '[]') && <p className="card-text"><I18n t="main.PhaseVoteProposals" /> ({Moment(minV).format('DD/MM/YYYY HH:mm') + ' - ' + Moment(maxV).format('DD/MM/YYYY HH:mm')})</p>}
																				{Moment().isAfter(maxV) && <p className="card-text"><I18n t="main.PhaseResults" /></p>}
																			</div>
																		</div>
																	</div>
																</div>
															);
														}
													)
													: <ul className="timeline mt-4"><I18n t="main.NoavailableClosedIssues" /></ul>
												:
												<Loading />
										}
									</div>
								</div>
								<div className="tab-pane fade" id="tab3" role="tabpanel" aria-labelledby="nav-tab3">
									<div>
										{
											futureIssues ?
												futureIssues.length > 0 ?
													futureIssues.map(
														issue => {
															const tags = [];

															for (let i = 0; i < issue[1].tags.length; i++) {
																tags.push(<span className="badge rounded-pill bg-info mr-2" key={i}>{issue[1].tags[i].label}</span>);
															}
															const now = Moment().format('YYYY-MM-DD HH:mm:ss a');
															const minSI = issue[1].supportIssuePeriodFrom ? Moment(issue[1].supportIssuePeriodFrom, 'YYYY-MM-DD HH:mm:ss a').format() : now;
															const maxSI = issue[1].supportIssuePeriodTo ? Moment(issue[1].supportIssuePeriodTo, 'YYYY-MM-DD HH:mm:ss a').format() : now;
															const minSP = issue[1].supportProposalPeriodFrom ? Moment(issue[1].supportProposalPeriodFrom, 'YYYY-MM-DD HH:mm:ss a').format() : now;
															const maxSP = issue[1].supportProposalPeriodTo ? Moment(issue[1].supportProposalPeriodTo, 'YYYY-MM-DD HH:mm:ss a').format() : now;
															const minC = issue[1].commentProposalPeriodFrom ? Moment(issue[1].commentProposalPeriodFrom, 'YYYY-MM-DD HH:mm:ss a').format() : now;
															const maxC = issue[1].commentProposalPeriodTo ? Moment(issue[1].commentProposalPeriodTo, 'YYYY-MM-DD HH:mm:ss a').format() : now;
															const minV = issue[1].voteProposalPeriodFrom ? Moment(issue[1].voteProposalPeriodFrom, 'YYYY-MM-DD HH:mm:ss a').format() : now;
															const maxV = issue[1].voteProposalPeriodTo ? Moment(issue[1].voteProposalPeriodTo, 'YYYY-MM-DD HH:mm:ss a').format() : now;

															return (
																<div className="card" style={{ backgroundColor: "#ffffff" }} key={issue[1]._id}>
																	<div className="card-body">
																		<div className="row">
																			<div className="col col-md-9">
																				<h5 className="card-title">
																					<Route path={this.props.base} render={() =>
																						<Link to={"/proposer/issue/" + issue[1]._id}>{issue[1].title}</Link>
																					} />
																				</h5>
																				{issue[1].description && <p className="card-text"><I18n t="main.IssueDescription" />: {issue[1].description}</p>}
																				{tags}
																			</div>
																			<div className="col col-md-3 border-start">
																				<p className="card-text">
																					{issue[1].supportIssue != null ? issue[1].supportIssue : ''}
																					{issue[1].supportIssue != null ? <I18n t="main.supportsneeded" /> : ''}
																				</p>
																				{Moment().isBetween(minSI, maxSI, undefined, '[]') && <p className="card-text"><I18n t="main.PhaseSupportingIssue" /> ({Moment(minSI).format('DD/MM/YYYY HH:mm') + ' - ' + Moment(maxSI).format('DD/MM/YYYY HH:mm')})</p>}
																				{Moment().isBetween(minSP, maxSP, undefined, '[]') && <p className="card-text"><I18n t="main.PhaseSupportingProposals" /> ({Moment(minSP).format('DD/MM/YYYY HH:mm') + ' - ' + Moment(maxSP).format('DD/MM/YYYY HH:mm')})</p>}
																				{Moment().isBetween(minC, maxC, undefined, '[]') && <p className="card-text"><I18n t="main.PhaseCommentProposals" /> ({Moment(minC).format('DD/MM/YYYY HH:mm') + ' - ' + Moment(maxC).format('DD/MM/YYYY HH:mm')})</p>}
																				{Moment().isBetween(minV, maxV, undefined, '[]') && <p className="card-text"><I18n t="main.PhaseVoteProposals" /> ({Moment(minV).format('DD/MM/YYYY HH:mm') + ' - ' + Moment(maxV).format('DD/MM/YYYY HH:mm')})</p>}
																				{Moment().isAfter(maxV) && <p className="card-text"><I18n t="main.PhaseResults" /></p>}
																			</div>
																		</div>
																	</div>
																</div>
															);
														}
													)
													: <ul className="timeline mt-4"><I18n t="main.NoavailableFuturesIssues" /></ul>
												:
												<Loading />
										}
									</div>
								</div>
							</div>
						</main>
					</div>
				</div>
				<Footer />
			</>
		);
	}
}
