import React, { Component } from 'react';
import { Route } from "react-router-dom";
import { Link, NavLink } from 'react-router-i18n';
import I18n from '../I18n';

import AuthService from "../services/auth.service";

class Header extends Component {
	constructor(props) {
		super(props);
		this.logOut = this.logOut.bind(this);

		this.state = {
			showCitizenBoard: false,
			showProposerBoard: false,
			showAdminBoard: false,
			currentUser: undefined
		};
	}

	componentDidMount() {
		const user = AuthService.getCurrentUser();

		if (user) {
			this.setState({
				currentUser: user,
				showCitizenBoard: user.roles.includes("ROLE_CITIZEN"),
				showProposerBoard: user.roles.includes("ROLE_PROPOSER"),
				showAdminBoard: user.roles.includes("ROLE_ADMIN")
			});
		}
	}

	logOut() {
		AuthService.logout();
	}

	render() {
		const { currentUser, showCitizenBoard, showProposerBoard, showAdminBoard } = this.state;

		return (
			<>
				<header className="govgr-header">
					<div className="govgr-header__container">
						<div className="govgr-header__content">
							<a href="/">
								<img className="govgr-header-logo" alt="logo" src="/assets/img/pods_logo.png" />
							</a>
						</div>
						<div className="text-white">
							<NavLink ignoreLocale to="/gr" className="link-light">Gr</NavLink> /
							<NavLink ignoreLocale to="/en" className="link-light"> En</NavLink>
						</div>
					</div>
				</header>
				{currentUser ? (
					<>
						<div className="govgr-width-container">
							<div className="govgr-phase-banner govgr-phase-banner__underline">
								<nav className="navbar navbar-expand-lg navbar-light bg-light">
									<div className="container-fluid">
										<button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
											<span className="navbar-toggler-icon"></span>
										</button>
										<div className="collapse navbar-collapse" id="navbarNav">
											<ul className="navbar-nav">
												{showCitizenBoard && (
													<li className="nav-item">
														<Route path={this.props.base} render={() =>
															<Link to={"/citizen"} className="nav-link active">
																<I18n t="header.CitizenBoard" />
															</Link>
														} />
													</li>
												)}

												{showCitizenBoard && (
													<li className="nav-item">
														<Route path={this.props.base} render={() =>
															<Link to={"/citizen/my-comments"} className="nav-link">
																<I18n t="header.Mycomments" />
															</Link>
														} />
													</li>
												)}

												{showCitizenBoard && (
													<li className="nav-item">
														<Route path={this.props.base} render={() =>
															<Link to={"/citizen/my-supports"} className="nav-link">
																<I18n t="header.MySupports" />
															</Link>
														} />
													</li>
												)}

												{showProposerBoard && (
													<li className="nav-item">
														<Route path={this.props.base} render={() =>
															<Link to={"/proposer"} className="nav-link active">
																<I18n t="header.ProposerBoard" />
															</Link>
														} />
													</li>
												)}

												{showAdminBoard && (
													<li className="nav-item">
														<Route path={this.props.base} render={() =>
															<Link to={"/admin"} className="nav-link active">
																<I18n t="header.AdminBoard" />
															</Link>
														} />
													</li>
												)}

												<li className="nav-item">
													<Route path={this.props.base} render={() =>
														<Link to={"/profile"} className="nav-link">
															{currentUser.username}
														</Link>
													} />
												</li>
												<li className="nav-item">
													<Route path={this.props.base} render={() =>
														<Link to="/login" className="nav-link" onClick={this.logOut}>
															<I18n t="header.LogOut" />
														</Link>
													} />
												</li>
											</ul>
										</div>
									</div>
								</nav>
							</div>
						</div>
					</>
				) : ('')}
			</>
		)
	}
}
export default Header;