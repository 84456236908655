import React, { Component } from "react";
import { Route } from "react-router-dom";
import { Link } from 'react-router-i18n';
import CheckButton from "react-validation/build/button";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import Textarea from "react-validation/build/textarea";
import I18n from '../I18n';
import AuthService from "../services/auth.service";
import ProposerProposalCommentEditService from "../services/proposer.proposal.comment.edit.service";
import Footer from './footer';
import Header from './header';
import Loading from './loading';

const required = value => {
	if (!value) {
		return (
			<p className="govgr-error-message">
				<I18n t="main.Thisfieldisrequired" />
			</p>
		);
	}
};

const vtitle = value => {
	if (value.length < 3) {
		return (
			<p className="govgr-error-message">
				<I18n t="main.Thetitlemustbegreaterthan3characters" />
			</p>
		);
	}
};

export default class ProposerProposalCommentEdit extends Component {
	constructor(props) {
		super(props);
		this.handleUpdate = this.handleUpdate.bind(this);
		this.onChangeTitle = this.onChangeTitle.bind(this);
		this.onChangeListComment = this.onChangeListComment.bind(this);

		this.state = {
			title: "",
			oldTitle: "",
			userId: AuthService.getCurrentUser().id,
			proposalId: this.props.match.params.proposalId,
			proposal: null,
			proposerName: null,
			proposalComments: null,
			listComments: [],
			successful: false,
			message: ""
		};
	}

	componentDidMount() {
		ProposerProposalCommentEditService.getProposal(this.state.proposalId).then(
			response => {
				this.setState({
					proposal: response.data.message,
					title: response.data.message[0].title
				});
			},
			error => {
				this.setState({
					proposal:
						(error.response &&
							error.response.data &&
							error.response.data.message) ||
						error.message ||
						error.toString()
				});
			}
		);

		ProposerProposalCommentEditService.getProposalProposer(this.state.proposalId).then(
			response => {
				this.setState({
					proposerName: response.data.message
				});
			},
			error => {
				this.setState({
					proposerName:
						(error.response &&
							error.response.data &&
							error.response.data.message) ||
						error.message ||
						error.toString()
				});
			}
		);

		ProposerProposalCommentEditService.getProposalComments(this.state.proposalId).then(
			response => {
				this.setState({
					proposalComments: response.data.message
				});
			},
			error => {
				this.setState({
					proposalComments:
						(error.response &&
							error.response.data &&
							error.response.data.message) ||
						error.message ||
						error.toString()
				});
			}
		);
	}

	onChangeTitle(e) {
		this.setState({
			title: e.target.value,
			oldTitle: e.target.dataset.oldTitle
		});
	}

	onChangeListComment(e) {
		// current array of options
		const listComments = this.state.listComments
		let index

		// check if the check box is checked or unchecked
		if (e.target.checked) {
			// add the numerical value of the checkbox to options array
			listComments.push(e.target.value)
		} else {
			// or remove the value from the unchecked checkbox from the array
			index = listComments.indexOf(e.target.value)
			listComments.splice(index, 1)
		}

		this.setState({
			listComments: listComments
		});
	}

	handleUpdate(e) {
		e.preventDefault();

		this.setState({
			message: "",
			successful: false
		});

		this.form.validateAll();

		if (this.checkBtn.context._errors.length === 0) {
			ProposerProposalCommentEditService.update(
				this.state.title,
				this.state.oldTitle,
				this.state.listComments,
				this.state.userId,
				this.state.proposalId
			).then(
				response => {
					this.setState({
						message: response.data.message,
						successful: true
					});
				},
				error => {
					const resMessage =
						(error.response &&
							error.response.data &&
							error.response.data.message) ||
						error.message ||
						error.toString();

					this.setState({
						successful: false,
						message: resMessage
					});
				}
			);
		}
	}

	render() {
		const proposal = this.state.proposal;
		const proposer = this.state.proposerName;
		const proposalComments = this.state.proposalComments;
		return (
			<>
				<Header />
				<div className="govgr-width-container">
					<div className="govgr-breadcrumbs">
						<ol className="govgr-breadcrumbs__list">
							<li className="govgr-breadcrumbs__list-item">
								<Route path={this.props.base} render={() =>
									<Link className="govgr-breadcrumbs__link" to="/proposer"><I18n t="main.Issues" /></Link>
								} />
							</li>
							<li className="govgr-breadcrumbs__list-item">
								{
									proposal ?
										Object.keys(proposal).map(
											key => {
												return <Route key={key} path={this.props.base} render={() =>
													<Link key={key} className="govgr-breadcrumbs__link" to={"/proposer/issue/" + proposal[key].issue[0]._id}>{proposal[key].issue[0].title}</Link>
												} />;
											}
										)
										:
										"Loading..."
								}
							</li>
							<li className="govgr-breadcrumbs__list-item">
								{
									proposal ?
										Object.keys(proposal).map(
											key => {
												return <Route key={key} path={this.props.base} render={() =>
													<Link key={key} className="govgr-breadcrumbs__link" to={"/proposer/proposal/" + proposal[key]._id}>{proposal[key].title}</Link>
												} />;
											}
										)
										:
										"Loading..."
								}
							</li>
						</ol>
					</div>
					{!this.state.successful && (
						<>
							{
								proposal ?
									Object.keys(proposal).map(
										key => {
											return <div key={key}>
												<h1 className="govgr-heading-l"><I18n t="main.EditProposal" />: {proposal[key].title}</h1>
												<Form
													onSubmit={this.handleUpdate}
													ref={c => {
														this.form = c;
													}}
												>
													<div className="govgr-field">
														<label className="govgr-label">
															<span role="heading" aria-level="1" className="govgr-heading-m"><I18n t="main.ProposalTitleandDescription" /></span>
															<p className="govgr-hint"><I18n t="main.ChangeTitleandDescriptionofProposal" /></p>
															<p className="govgr-hint">
																{proposer ? <I18n t="main.Proposer" /> : <Loading />}
																{proposer ? ": " + proposer : <Loading />}
															</p>
															<Textarea
																className="govgr-textarea"
																rows="5"
																name="proposal"
																value={proposal[key].title}
																data-old-title={proposal[key].title}
																onChange={this.onChangeTitle}
																validations={[required, vtitle]}
															/>
														</label>
													</div>
													<h3><I18n t="main.ProposalComments" /></h3>
													<div>
														<ul>
															{
																proposalComments ?
																	Object.keys(proposalComments).length > 0 ?
																		Object.keys(proposalComments).map(
																			key => {
																				return <div className="govgr-checkboxes" key={key}>
																					<div className="govgr-checkboxes__item">
																						<label className="govgr-label govgr-checkboxes__label">
																							{proposalComments[key].title}
																							<Input
																								className="govgr-checkboxes__input"
																								type="checkbox"
																								name={key}
																								value={proposalComments[key]._id}
																								onChange={this.onChangeListComment}
																							/>
																						</label>
																					</div>
																				</div>;
																			}
																		)
																		: <I18n t="main.NoavailableProposalComments" />
																	: "Loading..."
															}
														</ul>
													</div>
													<div className="govgr-button-group">
														<button className="govgr-btn govgr-btn-primary">
															<I18n t="main.SaveProposal" />
														</button>
														<Route path={this.props.base} render={() =>
															<Link key={key} className="govgr-btn govgr-btn-secondary mt-2" to={"/proposer/proposal/" + proposal[key]._id}><I18n t="main.Back" /></Link>
														} />
													</div>

													<CheckButton
														style={{ display: "none" }}
														ref={c => {
															this.checkBtn = c;
														}}
													/>
												</Form>
											</div>;
										}
									)
									:
									<Loading />
							}
						</>
					)}

					{this.state.message && (
						<div className="form-group">
							<div
								className={
									this.state.successful
										? "alert alert-success"
										: "alert alert-danger"
								}
								role="alert"
							>
								{this.state.message}<br />
								{
									proposal ?
										Object.keys(proposal).map(
											key => {
												return <Route key={key} path={this.props.base} render={() =>
													<Link key={key} className="govgr-btn govgr-btn-secondary mt-2" to={"/proposer/proposal/" + proposal[key]._id}><I18n t="main.Back" /></Link>
												} />;
											}
										)
										:
										<Loading />
								}
							</div>
						</div>
					)}
				</div>
				<Footer />
			</>
		);
	}
}
