import axios from 'axios';
import authHeader from './auth-header';

const API_PROPOSER_URL = 'https://www.podsweb-backend.dynacomp2.eu/api/pods/proposer/issue/';

class ProposerIssueService {

	getIssue(issue) {
		return axios.get(API_PROPOSER_URL, {
			headers: authHeader(),
			params: {
				issue: issue
			},
		}
		);
	}

	getIssueCreator(issue) {
		return axios.get(API_PROPOSER_URL + "creator", {
			headers: authHeader(),
			params: {
				issue: issue
			},
		}
		);
	}

	getIssueProposals(issue, userId) {
		return axios.get(API_PROPOSER_URL + "proposals", {
			headers: authHeader(),
			params: {
				issue: issue,
				userId: userId
			},
		}
		);
	}
}

export default new ProposerIssueService();
