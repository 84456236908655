import axios from 'axios';
import authHeader from './auth-header';

const API_PROPOSER_URL = 'https://www.podsweb-backend.dynacomp2.eu/api/pods/proposer/proposal/edit/';

class ProposerProposalCommentEditService {

	update(title, oldTitle, listComments, userId, proposalId) {
		return axios.post(API_PROPOSER_URL + "update", {
			title,
			oldTitle,
			listComments,
			userId,
			proposalId
		},
			{ headers: authHeader() }
		);
	}

	getProposal(proposal) {
		return axios.get(API_PROPOSER_URL, {
			headers: authHeader(),
			params: {
				proposal: proposal
			},
		}
		);
	}

	getProposalProposer(proposal) {
		return axios.get(API_PROPOSER_URL + "proposer", {
			headers: authHeader(),
			params: {
				proposal: proposal
			},
		}
		);
	}

	getProposalComments(proposal) {
		return axios.get(API_PROPOSER_URL + "comments", {
			headers: authHeader(),
			params: {
				proposal: proposal
			},
		}
		);
	}

}

export default new ProposerProposalCommentEditService();
