import Moment from 'moment';
import React, { Component } from "react";
import { Route } from "react-router-dom";
import { Link } from 'react-router-i18n';
import I18n from '../I18n';
import AuthService from "../services/auth.service";
import ResultIssueService from "../services/result.issue.service";
import Footer from './footer';
import Header from './header';
import Loading from './loading';

export default class ResultIssue extends Component {
	constructor(props) {
		super(props);
		if(AuthService.getCurrentUser() === null){
			window.location.href = '/';
		}

		this.state = {
			currentUser: AuthService.getCurrentUser(),
			issueId: this.props.match.params.issueId,
			issue: null,
			adminName: null,
			issueProposals: null
		};
	}

	componentDidMount() {
		ResultIssueService.getIssue(this.state.issueId).then(
			response => {
				this.setState({
					issue: response.data.message
				});
			},
			error => {
				this.setState({
					issue:
						(error.response &&
							error.response.data &&
							error.response.data.message) ||
						error.message ||
						error.toString()
				});
			}
		);

		ResultIssueService.getIssueCreator(this.state.issueId).then(
			response => {
				this.setState({
					adminName: response.data.message
				});
			},
			error => {
				this.setState({
					adminName:
						(error.response &&
							error.response.data &&
							error.response.data.message) ||
						error.message ||
						error.toString()
				});
			}
		);

		ResultIssueService.getIssueProposals(this.state.issueId).then(
			response => {
				this.setState({
					issueProposals: response.data.message
				});
			},
			error => {
				this.setState({
					issueProposals:
						(error.response &&
							error.response.data &&
							error.response.data.message) ||
						error.message ||
						error.toString()
				});
			}
		);
	}
	render() {
		const issue = this.state.issue;
		const creator = this.state.adminName;
		const issueProposals = this.state.issueProposals;
		return (
			<><Header />
				<div className="govgr-width-container">
					<div className="govgr-breadcrumbs">
						<ol className="govgr-breadcrumbs__list">
							<li className="govgr-breadcrumbs__list-item">
								<Route path={this.props.base} render={() =>
									<Link className="govgr-breadcrumbs__link" to={this.state.currentUser.roles.includes("ROLE_ADMIN") ? '/admin' : (this.state.currentUser.roles.includes("ROLE_PROPOSER") ? '/proposer' : '/citizen')}><I18n t="main.Issues" /></Link>
								} />
							</li>
							<li className="govgr-breadcrumbs__list-item">
								{
									issue ?
										<Route path={this.props.base} render={() =>
											<Link className="govgr-breadcrumbs__link" to={(this.state.currentUser.roles.includes("ROLE_ADMIN") ? '/admin' : (this.state.currentUser.roles.includes("ROLE_PROPOSER") ? '/proposer' : '')) + "/issue/" + issue[0]._id}>{issue[0].title}</Link>
										} />
										: "Loading..."
								}
							</li>
						</ol>
					</div>
					<div className="govgr-main-wrapper">
						<main className="govgr-grid-column-third-thirds">
							{
								issue ?
									<h1 className="govgr-heading-l"><I18n t="main.Results" />: {issue[0].title}</h1>
									:
									(
										<div className="govgr-warning-text">
											<span className="govgr-warning-text__icon">!</span>
											<strong className="govgr-warning-text__text">
												<span className="govgr-warning-text__assistive">Loading</span>
												Loading
											</strong>
										</div>
									)
							}
							{
								issue ? (<p className="govgr-body"><I18n t="main.Views" />: {issue[0].citizenViewCount}</p>) : <Loading />
							}
							{
								creator ? (<p className="govgr-body"><I18n t="main.Creator" />: {creator}</p>) : <Loading />
							}
							{
								issue ? (<p className="govgr-body"><I18n t="main.CurrentRound" />: {issue[0].nextRound}</p>) : <Loading />
							}
							<hr />
							<h3>
								<I18n t="main.IssueProposals" />
							</h3>
							{
								issueProposals ?
									Object.keys(issueProposals).length > 0 ?
										Object.keys(issueProposals).map(
											key => {
												const issueVoteLimits = issueProposals[key].issueVoteLimits;
												const issueMyVoteLikes = issueProposals[key].likevotes;
												const issueMyVoteDislikes = issueProposals[key].dislikevotes;
												const issueMyVoteNeutrals = issueProposals[key].neutralvotes;
												return <div key={key} className="card">
													<h4><I18n t="main.ProposalInfo" /></h4>
													<div>
														{issueProposals[key].proposal ? issueProposals[key].proposal.title + " ": <Loading />}
														{issueProposals[key].proposal ? <I18n t="main.views" /> : <Loading />}
														{issueProposals[key].proposal ? ": (" + issueProposals[key].proposal.citizenViewCount + ")" : <Loading />}
													</div>
													<div>
														{
															issueProposals[key].proposal.user[0] ? issueProposals[key].proposal.user.map(u => <span key={u._id}>{u.username}</span>)
																.reduce((prev, curr) => [prev, ', ', curr]) : <Loading />
														}
													</div>
													<hr />
													<div>
														<h4><I18n t="main.ProposalSupportsCount" /></h4>
														{
															issueProposals[key].proposal ? issueProposals[key].support === 0 ? "-" : issueProposals[key].support : <Loading />
														}
													</div>
													<hr />
													<div>
														<h4><I18n t="main.ProposalStatisticsCount" /></h4>
														{issueProposals[key].statistics ? <I18n t="main.TotalComments" /> : <Loading />}
														{issueProposals[key].statistics ? issueProposals[key].statistics.commentMap : <Loading />}
														{issueProposals[key].statistics ? <I18n t="main.CommentsInHistory" /> : <Loading />}
														{issueProposals[key].statistics ? issueProposals[key].statistics.commentHistoryMap : <Loading />}
														{issueProposals[key].statistics ? <I18n t="main.CommentInHistoryWithVerification" /> : <Loading />}
														{issueProposals[key].statistics ? issueProposals[key].statistics.commentHistoryVerifiedMap : <Loading />}
													</div>
													<hr />
													<h4><I18n t="main.ProposalHistory" /></h4>
													<ul key={'ul' + key} className="timeline mt-4">
														{
															issueProposals[key].history ?
																Object.keys(issueProposals[key].history).length > 0 ?
																	Object.keys(issueProposals[key].history).map(
																		hkey => {
																			var dt = new Date(issueProposals[key].history[hkey].createdAt).toISOString().replace(/T/, ' ').replace(/\..+/, '');
																			return <li key={hkey} className="event" data-date={Moment(dt).format('DD/MM/yyyy HH:mm:ss')}>
																				<h3>{issueProposals[key].history[hkey].title}</h3>
																				<p><I18n t="main.PreviousTitle" />: {issueProposals[key].history[hkey].oldTitle}</p>
																				{
																					issueProposals[key].history[hkey].proposalComments ? Object.keys(issueProposals[key].history[hkey].proposalComments).map(
																						keys => {
																							return <p className="card-text" key={keys}>{issueProposals[key].history[hkey].proposalComments[keys].title}</p>;
																						}
																					) : "Loading..."
																				}
																			</li>;
																		}
																	) : <I18n t="main.NoavailableProposalHistory" />
																: "Loading..."
														}
													</ul>
													<hr />
													<h4><I18n t="main.ProposalComments" /></h4>
													<div>
														<ul>
															{
																issueProposals[key].comments ? Object.keys(issueProposals[key].comments).map(
																	pkey => {
																		return <li key={pkey}>{issueProposals[key].comments[pkey].title}</li>;
																	}
																) : "Loading..."
															}
														</ul>
													</div>
													<hr />
													<h4><I18n t="main.DetailsofproposalcreatorProposalORmergeRequestProposalsmergerequestshistory" />:</h4>
													{issueProposals[key].mergeRequest ?
														Object.keys(issueProposals[key].mergeRequest).map(
															mrkey => {
																return <div key={mrkey}><div className="card">
																	<div className="card-body">
																		<p className="card-title">Merge proposal with:</p>
																		<ul>
																			{
																				issueProposals[key].mergeRequest[mrkey].creatorProposal ?
																					Object.keys(issueProposals[key].mergeRequest[mrkey].creatorProposal).map(
																						keyP => {
																							return <li key={keyP}>{issueProposals[key].mergeRequest[mrkey].creatorProposal[keyP].title}</li>;
																						}
																					)
																					:
																					"Loading..."
																			}
																		</ul>
																		<p className="card-title"><I18n t="main.Frommergerequestcreator" />:</p>
																		<ul>
																			{
																				issueProposals[key].mergeRequest[mrkey].creator ?
																					Object.keys(issueProposals[key].mergeRequest[mrkey].creator).map(
																						keyC => {
																							return <li key={keyC}>{issueProposals[key].mergeRequest[mrkey].creator[keyC].username}
																								(e-mail: {issueProposals[key].mergeRequest[mrkey].creator[keyC].email})</li>;
																						}
																					)
																					:
																					"Loading..."
																			}
																		</ul>
																		<p className="card-title"><I18n t="main.mergerequestProposalswithProposers" />:</p>
																		<ul>
																			{
																				issueProposals[key].mergeRequest[mrkey].mergeRequestProposals ?
																					Object.keys(issueProposals[key].mergeRequest[mrkey].mergeRequestProposals).map(
																						keyMrp => {
																							return <li key={keyMrp}>{issueProposals[key].mergeRequest[mrkey].mergeRequestProposals[keyMrp].title} (
																								{
																									Object.keys(issueProposals[key].mergeRequest[mrkey].mergeRequestProposals[keyMrp].user).map(
																										keyMrpp => {
																											return <span key={keyMrpp}>{issueProposals[key].mergeRequest[mrkey].mergeRequestProposals[keyMrp].user[keyMrpp].username}
																												(e-mail: {issueProposals[key].mergeRequest[mrkey].mergeRequestProposals[keyMrp].user[keyMrpp].email})</span>;
																										}
																									)
																								}
																								)</li>;
																						}
																					)
																					:
																					"Loading..."
																			}
																		</ul>
																	</div>
																	<div className="card-footer">
																		<I18n t="main.Mergerequeststatus" />: <b><I18n t={"main."+issueProposals[key].mergeRequest[mrkey].status} /></b>, <I18n t="main.Mergerequestaccept" />: <b>{issueProposals[key].mergeRequest[mrkey].accept}</b>
																		<span className="float-right">{issueProposals[key].mergeRequest[mrkey].createdAt}</span>
																	</div>
																</div></div>;
															}
														)
														:
														<Loading />
													}
													<hr />
													<h4><I18n t="main.Vote" /></h4>
													<div className="alert alert-info">
														<p> <I18n t="main.VotesperIssue" /> (<I18n t="main.TotalVotes" /> / <I18n t="main.issueLimits" />) </p>
														<button className="btn btn-success">
															<I18n t="main.Likes" />: {issueMyVoteLikes !== null ? issueMyVoteLikes === 0 ? "0" : issueMyVoteLikes : "Loading..."} / {issueVoteLimits ? issueVoteLimits.issue_max_likes : "Loading..."}
														</button>
														<button className="btn btn-danger ml-2">
															<I18n t="main.Dislikes" />: {issueMyVoteDislikes !== null ? issueMyVoteDislikes === 0 ? "0" : issueMyVoteDislikes : "Loading..."} / {issueVoteLimits ? issueVoteLimits.issue_max_dislikes : "Loading..."}
														</button>
														<button className="btn btn-secondary ml-2">
															<I18n t="main.Neutral" />: {issueMyVoteNeutrals !== null ? issueMyVoteNeutrals === 0 ? "0" : issueMyVoteNeutrals : "Loading..."} / {issueVoteLimits ? issueVoteLimits.issue_max_neutral : "Loading..."}
														</button>
													</div>
												</div>;
											}
										)
										: <ul className="timeline mt-4"> <I18n t="main.NoavailableIssueProposals" /></ul>
									: <Loading />
							}
						</main>
					</div>
				</div>
				<Footer />
			</>
		);
	}
}
