import axios from 'axios';
import authHeader from './auth-header';

const API_CITIZEN_URL = 'https://www.podsweb-backend.dynacomp2.eu/api/pods/citizen/';

class CitizenSupportIssueService {

	create(userId, issueId) {
		return axios.post(API_CITIZEN_URL + "issue/support", {
			userId,
			issueId
		},
			{ headers: authHeader() }
		);
	}

	getIssue(issue) {
		return axios.get(API_CITIZEN_URL + "support/issue", {
			headers: authHeader(),
			params: {
				issue: issue
			},
		}
		);

	}

}

export default new CitizenSupportIssueService();
