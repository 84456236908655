import axios from 'axios';
import authHeader from './auth-header';

const API_URL = 'https://www.podsweb-backend.dynacomp2.eu/api/pods/';

class UserService {
	getPublicContent() {
		return axios.get(API_URL + 'all');
	}

	getCitizenBoard() {
		return axios.get(API_URL + 'citizen', { headers: authHeader() });
	}

	getProposerBoard() {
		return axios.get(API_URL + 'proposer', { headers: authHeader() });
	}

	getAdminBoard() {
		return axios.get(API_URL + 'admin', { headers: authHeader() });
	}
}

export default new UserService();
