import React, { Component } from "react";
import CheckButton from "react-validation/build/button";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import { isEmail } from "validator";
import I18n from '../I18n';
import AuthService from "../services/auth.service";
import Footer from './footer';
import Header from './header';

const required = value => {
	if (!value) {
		return (
			<p className="govgr-error-message">
				<I18n t="main.Thisfieldisrequired" />
			</p>
		);
	}
};

const email = value => {
	if (!isEmail(value)) {
		return (
			<div className="alert alert-danger" role="alert">
				<I18n t="main.Thisisnotavalidemail" />
			</div>
		);
	}
};

const vusername = value => {
	if (value.length < 3 || value.length > 20) {
		return (
			<div className="alert alert-danger" role="alert">
				<I18n t="main.Theusernamemustbebetween3and20characters" />
			</div>
		);
	}
};

const vpassword = value => {
	if (value.length < 6 || value.length > 40) {
		return (
			<div className="alert alert-danger" role="alert">
				<I18n t="main.Thepasswordmustbebetween6and40characters" />
			</div>
		);
	}
};

const vpostalCode = value => {
	if (value.length !== 5) {
		return (
			<div className="alert alert-danger" role="alert">
				<I18n t="main.ThePostalCodemustbe5characters" />
			</div>
		);
	}
	if (!value.match(/^[0-9]{5}/)) {
		return (
			<div className="alert alert-danger" role="alert">
				<I18n t="main.ThePostalCodemustcontainonly5numbers" />
			</div>
		);
	}
};

export default class Register extends Component {
	constructor(props) {
		super(props);
		this.handleRegister = this.handleRegister.bind(this);
		this.onChangeUsername = this.onChangeUsername.bind(this);
		this.onChangeEmail = this.onChangeEmail.bind(this);
		this.onChangePassword = this.onChangePassword.bind(this);
		this.onChangeOccupation = this.onChangeOccupation.bind(this);
		this.onChangeStreet = this.onChangeStreet.bind(this);
		this.onChangeStreetNumber = this.onChangeStreetNumber.bind(this);
		this.onChangePostalCode = this.onChangePostalCode.bind(this);
		this.onChangeCity = this.onChangeCity.bind(this);

		this.state = {
			username: "",
			email: "",
			password: "",
			occupation: "",
			street: "",
			streetNumber: "",
			postalCode: "",
			city: "",
			roles: ["citizen"],
			successful: false,
			message: ""
		};
	}

	onChangeUsername(e) {
		this.setState({
			username: e.target.value
		});
	}

	onChangeEmail(e) {
		this.setState({
			email: e.target.value
		});
	}

	onChangePassword(e) {
		this.setState({
			password: e.target.value
		});
	}

	onChangeOccupation(e) {
		this.setState({
			occupation: e.target.value
		});
	}

	onChangeStreet(e) {
		this.setState({
			street: e.target.value
		});
	}

	onChangeStreetNumber(e) {
		this.setState({
			streetNumber: e.target.value
		});
	}

	onChangePostalCode(e) {
		this.setState({
			postalCode: e.target.value
		});
	}

	onChangeCity(e) {
		this.setState({
			city: e.target.value
		});
	}

	handleRegister(e) {
		e.preventDefault();

		this.setState({
			message: "",
			successful: false
		});

		this.form.validateAll();

		if (this.checkBtn.context._errors.length === 0) {
			AuthService.register(
				this.state.username,
				this.state.email,
				this.state.password,
				this.state.occupation,
				this.state.street,
				this.state.streetNumber,
				this.state.postalCode,
				this.state.city,
				this.state.roles
			).then(
				response => {
					this.setState({
						message: response.data.message,
						successful: true
					});
				},
				error => {
					const resMessage =
						(error.response &&
							error.response.data &&
							error.response.data.message) ||
						error.message ||
						error.toString();

					this.setState({
						successful: false,
						message: resMessage
					});
				}
			);
		}
	}

	render() {
		return (
			<><Header />
				<section>
					<div className="col-md-12">
						<div className="card card-container" style={{ marginBottom: '25px' }}>
							<img
								src="//ssl.gstatic.com/accounts/ui/avatar_2x.png"
								alt="profile-img"
								className="profile-img-card"
							/>

							<Form
								onSubmit={this.handleRegister}
								ref={c => {
									this.form = c;
								}}
							>
								{!this.state.successful && (
									<div>
										<div className="govgr-field">
											<label className="govgr-label">
												<span role="heading" aria-level="1" className="govgr-heading-m"><I18n t="main.Username" /></span>
												<Input
													type="text"
													className="govgr-input"
													name="username"
													value={this.state.username}
													onChange={this.onChangeUsername}
													validations={[required, vusername]}
												/>
											</label>
										</div>

										<div className="govgr-field">
											<label className="govgr-label">
												<span role="heading" aria-level="1" className="govgr-heading-m">E-mail</span>
												<Input
													type="text"
													className="govgr-input"
													name="email"
													value={this.state.email}
													onChange={this.onChangeEmail}
													validations={[required, email]}
												/>
											</label>
										</div>

										<div className="govgr-field">
											<label className="govgr-label">
												<span role="heading" aria-level="1" className="govgr-heading-m"><I18n t="main.Password" /></span>
												<Input
													type="password"
													className="govgr-input"
													name="password"
													value={this.state.password}
													onChange={this.onChangePassword}
													validations={[required, vpassword]}
												/>
											</label>
										</div>

										<div className="govgr-field">
											<label className="govgr-label">
												<span role="heading" aria-level="1" className="govgr-heading-m"><I18n t="main.Occupation" /></span>
												<select
													name="occupation"
													className="govgr-select"
													value={this.state.occupation}
													onChange={this.onChangeOccupation}
													validations={[required]}
												>
													<option value="">----</option>
													<option value="HMMY Student">Φοιτητής/τρια ΗΜΜΥ</option>
													<option value="Alumni">Απόφοιτος/η (Μηχανικών Πληροφορικής Τ.Ε.,
														Ηλεκτρολόγων Τ.Ε., ΤΕΣΥΔ)</option>
													<option value="Professor">Καθηγητής/τρια</option>
												</select>
											</label>
										</div>

										<div className="govgr-field">
											<label className="govgr-label">
												<span role="heading" aria-level="1" className="govgr-heading-m"><I18n t="main.Street" /></span>
												<Input
													type="text"
													className="govgr-input"
													name="street"
													value={this.state.street}
													onChange={this.onChangeStreet}
													validations={[required]}
												/>
											</label>
										</div>

										<div className="govgr-field">
											<label className="govgr-label">
												<span role="heading" aria-level="1" className="govgr-heading-m"><I18n t="main.StreetNumber" /></span>
												<Input
													type="text"
													className="govgr-input"
													name="streetNumber"
													value={this.state.streetNumber}
													onChange={this.onChangeStreetNumber}
													validations={[required]}
												/>
											</label>
										</div>

										<div className="govgr-field">
											<label className="govgr-label">
												<span role="heading" aria-level="1" className="govgr-heading-m"><I18n t="main.PostalCode" /></span>
												<Input
													type="text"
													className="govgr-input"
													name="postalCode"
													value={this.state.postalCode}
													onChange={this.onChangePostalCode}
													validations={[required, vpostalCode]}
												/>
											</label>
										</div>

										<div className="govgr-field">
											<label className="govgr-label">
												<span role="heading" aria-level="1" className="govgr-heading-m"><I18n t="main.City" /></span>
												<Input
													type="text"
													className="govgr-input"
													name="city"
													value={this.state.city}
													onChange={this.onChangeCity}
													validations={[required]}
												/>
											</label>
										</div>

										<div className="form-group">
											<button className="btn btn-primary btn-block"><I18n t="main.SignUp" /></button>
										</div>
									</div>
								)}

								{this.state.message && (
									<div className="form-group">
										<div
											className={
												this.state.successful
													? "alert alert-success"
													: "alert alert-danger"
											}
											role="alert"
										>
											{this.state.message}
										</div>
									</div>
								)}
								<CheckButton
									style={{ display: "none" }}
									ref={c => {
										this.checkBtn = c;
									}}
								/>
							</Form>
						</div>
					</div>
				</section>
				<Footer /></>
		);
	}
}
