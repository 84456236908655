import Moment from 'moment';
import React, { Component } from "react";
import { Route } from "react-router-dom";
import { Link } from 'react-router-i18n';
import I18n from '../I18n';
import AuthService from "../services/auth.service";
import ProposerIssueMergeService from "../services/proposer.issue.merge.service";
import Footer from './footer';
import Header from './header';
import Loading from './loading';

export default class ProposerIssueMergeRequestHistory extends Component {
	constructor(props) {
		super(props);
		if(AuthService.getCurrentUser() === null){
			window.location.href = '/';
		}

		this.state = {
			userId: AuthService.getCurrentUser().id,
			issueId: this.props.match.params.issueId,
			issue: null,
			adminName: null,
			issueProposals: null,
			myProposal: null,
			proposalsOfOtherProposers: null,
			mergeRequest: null,
			successful: false,
			message: ""
		};
	}

	componentDidMount() {
		ProposerIssueMergeService.getIssue(this.state.issueId).then(
			response => {
				this.setState({
					issue: response.data.message
				});
			},
			error => {
				this.setState({
					issue:
						(error.response &&
							error.response.data &&
							error.response.data.message) ||
						error.message ||
						error.toString()
				});
			}
		);

		ProposerIssueMergeService.getIssueCreator(this.state.issueId).then(
			response => {
				this.setState({
					adminName: response.data.message
				});
			},
			error => {
				this.setState({
					adminName:
						(error.response &&
							error.response.data &&
							error.response.data.message) ||
						error.message ||
						error.toString()
				});
			}
		);

		ProposerIssueMergeService.getIssueProposals(this.state.issueId).then(
			response => {
				this.setState({
					issueProposals: response.data.message
				});
			},
			error => {
				this.setState({
					issueProposals:
						(error.response &&
							error.response.data &&
							error.response.data.message) ||
						error.message ||
						error.toString()
				});
			}
		);

		ProposerIssueMergeService.getMergeRequestHistoryCreatorProposers(this.state.userId, this.state.issueId).then(
			response => {
				this.setState({
					mergeRequest: response.data.message
				});
			},
			error => {
				this.setState({
					mergeRequest:
						(error.response &&
							error.response.data &&
							error.response.data.message) ||
						error.message ||
						error.toString()
				});
			}
		);
	}

	proposalPerProposer = () => {
		if (this.state.issueProposals) {
			var issueProposals = this.state.issueProposals
			let myIssueProposal = [];
			Object.keys(issueProposals).filter((proposal) => issueProposals[proposal].user[0] === this.state.userId).map((proposal) =>
				myIssueProposal.push(issueProposals[proposal])
			);
			let issueProposalsOfOtherProposers = [];
			Object.keys(issueProposals).filter((proposal) => issueProposals[proposal].user[0] !== this.state.userId).map((proposal) =>
				issueProposalsOfOtherProposers.push(issueProposals[proposal])
			);
			return [
				{
					"myProposal": myIssueProposal,
					"proposalsOfOtherProposers": issueProposalsOfOtherProposers,
				}
			]
		}
	}

	render() {
		const issue = this.state.issue;
		const creator = this.state.adminName;
		const proposalPerProposer = this.proposalPerProposer();
		const myProposal = proposalPerProposer ? proposalPerProposer[0]['myProposal'] : null;
		const mergeRequest = this.state.mergeRequest ? this.state.mergeRequest.length > 0 ? this.state.mergeRequest : null : null;
		return (
			<><Header />
				<div className="govgr-width-container">
					<div className="govgr-breadcrumbs">
						<ol className="govgr-breadcrumbs__list">
							<li className="govgr-breadcrumbs__list-item">
								<Route path={this.props.base} render={() =>
									<Link className="govgr-breadcrumbs__link" to="/proposer"><I18n t="main.Issues" /></Link>
								} />
							</li>
							<li className="govgr-breadcrumbs__list-item">
								{
									issue ?
										Object.keys(issue).map(
											key => {
												return <Route key={key} path={this.props.base} render={() =>
													<Link key={key} className="govgr-breadcrumbs__link" to={"/proposer/issue/" + issue[key]._id}>{issue[key].title}</Link>
												} />;
											}
										)
										:
										"Loading..."
								}
							</li>
						</ol>
					</div>
					<div className="govgr-main-wrapper">
						<main className="govgr-grid-column-third-thirds">
							{
								issue ?
									Object.keys(issue).map(
										key => {
											return <div key={key}>
												<span className="govgr-caption-xl"><I18n t="main.IssueTitle" /></span>
												<h1 className="govgr-heading-l">{issue[key].title}</h1>
											</div>;
										}
									)
									:
									<Loading />
							}
							{
								creator ? (<p className="govgr-hint"><I18n t="main.Creator" />: {creator}</p>) : <Loading />
							}
							{
								myProposal ?
									Object.keys(myProposal).map(
										key => {
											return <div key={key}>
												<span className="govgr-caption-xl"><I18n t="main.MyProposal" /></span>
												<h1 className="govgr-heading-l">{myProposal[key].title}</h1>
											</div>;
										}
									)
									:
									<Loading />
							}
							{mergeRequest && (<div>
								<h1 className="govgr-heading-l"><I18n t="main.Detailsofmyproposalmergerequestshistory" /></h1>
								<ul className="timeline mt-4">
									{
										mergeRequest ?
											Object.keys(mergeRequest).map(
												key => {
													var dt = new Date(mergeRequest[key].createdAt).toISOString().replace(/T/, ' ').replace(/\..+/, '');
													return <li key={key} className="event" data-date={Moment(dt).format('DD/MM/yyyy HH:mm:ss')}>
														<p><I18n t="main.Mergemyproposalwith" />:</p>
														<ul>
															{
																mergeRequest[key].creatorProposal ?
																	Object.keys(mergeRequest[key].creatorProposal).map(
																		keyP => {
																			return <li key={keyP}>{mergeRequest[key].creatorProposal[keyP].title}</li>;
																		}
																	)
																	:
																	"Loading..."
															}
														</ul>
														<p><I18n t="main.Frommergerequestcreator" />:</p>
														<ul>
															{
																mergeRequest[key].creator ?
																	Object.keys(mergeRequest[key].creator).map(
																		keyC => {
																			return <li key={keyC}>{mergeRequest[key].creator[keyC].username} (e-mail: {mergeRequest[key].creator[keyC].email})</li>;
																		}
																	)
																	:
																	"Loading..."
															}
														</ul>
														<p><I18n t="main.mergerequestProposalswithProposers" />:</p>
														<ul>
															{
																mergeRequest[key].mergeRequestProposals ?
																	Object.keys(mergeRequest[key].mergeRequestProposals).map(
																		keyMrp => {
																			return <li key={keyMrp}>{mergeRequest[key].mergeRequestProposals[keyMrp].title} (
																				{
																					Object.keys(mergeRequest[key].mergeRequestProposals[keyMrp].user).map(
																						keyMrpp => {
																							return <span key={keyMrpp}>{mergeRequest[key].mergeRequestProposals[keyMrp].user[keyMrpp].username} (e-mail: {mergeRequest[key].mergeRequestProposals[keyMrp].user[keyMrpp].email})</span>;
																						}
																					)
																				}
																				)</li>;
																		}
																	)
																	:
																	"Loading..."
															}
														</ul>
														<p>
															<I18n t="main.Mergerequeststatus" />: <b><I18n t={"main."+mergeRequest[key].status} /></b>, <I18n t="main.Mergerequestaccept" />: <b>{mergeRequest[key].accept}</b>
														</p>
													</li>;
												}
											)
											:
											"Loading..."
									}
								</ul>
							</div>)}
						</main>
					</div>
				</div>
				<Footer /></>
		);
	}
}
