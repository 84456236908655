import axios from 'axios';
import authHeader from './auth-header';

const API_ADMIN_URL = 'https://www.podsweb-backend.dynacomp2.eu/api/pods/admin/issue/edit/';

class AdminIssueEditService {

	update(title, oldTitle, description, userId, issueId) {
		return axios.post(API_ADMIN_URL + "update", {
			title,
			oldTitle,
			description,
			userId,
			issueId
		},
			{ headers: authHeader() }
		);
	}

	getIssue(issue) {
		return axios.get(API_ADMIN_URL, {
			headers: authHeader(),
			params: {
				issue: issue
			},
		}
		);
	}

	getCreator(issue) {
		return axios.get(API_ADMIN_URL + "creator", {
			headers: authHeader(),
			params: {
				issue: issue
			},
		}
		);
	}

}

export default new AdminIssueEditService();
