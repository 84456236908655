import React, { Component } from "react";
import Footer from './footer';
import Header from './header';
import I18n from '../I18n';

export default class Help extends Component {

	constructor(props) {
		super(props);
		console.log(props);
	}
	render() {
		return (
			<>
				<Header />
				<div className="govgr-width-container">
					<div className="govgr-main-wrapper">
						<main className="govgr-grid-column-third-thirds">
							<h1 className="govgr-heading-xl"><I18n t="footer.Help" /></h1>
							<blockquote className="govgr-blockquote">
								<p>
									To PODS αποτελεί ένα διεθνώς πρότυπο σύστημα διαβούλευσης, που στηρίζετε
									στις θεμελιώδης θεωρητικές αρχές της διαβουλευτικής δημοκρατίας
									(deliberative democracy).
								</p>
								<p>
									Με βάση αυτές τις αρχές, έχει διαμορφωθεί το σύστημα PODS.
								</p>
								<p>
									Το σύστημα PODS έχει 3 φάσεις.
								</p>
								<p>
									<u>Φάση 1</u>
								</p>
								<p>
									Οι χρήστες υποβάλλουν την πρότασή τους πάνω στο θέμα που έχει οριστεί.
								</p>
								<p>
									<u>Φάση 2</u>
								</p>
								<p>
									Οι χρήστες έχουν τη δυνατότητα του σχολιασμού των υπάρχων προτάσεων, και οι
									δημιουργοί των προτάσεων μπορούν να ενσωματώσουν τα σχόλια εντός των δικών
									τους προτάσεων και να αιτηθούν τη συγχώνευση προτάσεων που μπορεί να
									μοιάζουν με τη δική τους.
								</p>
								<p>
									<u>Φάση 3</u>
								</p>
								<p>
									Οι χρήστες μπορούν να εκφράζουν την προτίμησή τους (like, dislike, neutral)
									στις προτάσεις.
								</p>
								<p>
									<u>Βασικές αρχές διαφάνειας. </u>
								</p>
								<ul className="govgr-list govgr-list--bullet ml-3">
									<li>
										Το σύστημα εγγυάται την διαφάνεια των πληροφοριών και την προσβασιμότητα
										όλων των χρηστών και διασφαλίζει με έγκυρο τρόπο χωρίς αποκλεισμούς τη
										καταγραφή του συνόλου των απόψεων που έχουν κατατεθεί.
									</li>
									<li>
										Το σύστημα παρουσιάζει τον αριθμό των ψήφων, τα κριτήρια ταξινόμησης των
										προτάσεων και τον τρόπο με τον οποίο συγχωνεύθηκαν ή απορρίφθηκαν οι
										προτάσεις.
									</li>
								</ul>
								<p>
									Για την συγκεκριμένη διαβούλευση, η διαχειριστική αρχή και προκειμένου να
									εξυπηρετηθεί η διαύγεια αναφέρει,
								</p>
								<ol className="govgr-list govgr-list--number ml-3">
									<li>
										Ο τρόπος με τον οποίο θα βαθμολογηθούν οι προτάσεις θα είναι like –
										dislike.
									</li>
									<li>
										Θα υπάρξουν δύο κύκλοι, όπου σε κάθε κύκλο θα απορρίπτονται οι δύο πιο
										αδύναμες και οι υπόλοιπες θα εισέρχονται σε μια επαναληπτική διαδικασία
										(Φάση 2 και Φάση 3).
									</li>
									<li>
										Στο τέλος οι προτάσεις που θα εμφανιστούν θα είναι οι προτάσεις που θα
										έχουν συγκεντρώσει τη μεγαλύτερη αποδοχή.
									</li>
									<li>
										Το σύστημα διατηρεί στο σύνολο του την διαδικασία της διαβούλευσης και
										όλα τα τυχόν υποστηρικτικά κείμενα για έλεγχο.
									</li>
									<li>
										Στην πιλοτική εφαρμογή δεν έχει προβλεφθεί η διαδικασία της ανωνυμίας.
									</li>
								</ol>
							</blockquote>
						</main>
						<main className="govgr-grid-column-two-thirds">
							<p className="govgr-body"><I18n t="main.helpInfo" /></p>
							<p className="govgr-body">
								<a href={"/" + (this.props.location.pathname.substring(1, 3) === 'he' ? 'gr' : this.props.location.pathname.substring(1, 3)) + "/help#_Login"}><I18n t="home.Login" /></a> |
								<a href={"/" + (this.props.location.pathname.substring(1, 3) === 'he' ? 'gr' : this.props.location.pathname.substring(1, 3)) + "/help#_Citizen_Board"}> <I18n t="header.CitizenBoard" /></a> |
								<a href={"/" + (this.props.location.pathname.substring(1, 3) === 'he' ? 'gr' : this.props.location.pathname.substring(1, 3)) + "/help#_Proposer_Board"}> <I18n t="header.ProposerBoard" /></a> |
								<a href={"/" + (this.props.location.pathname.substring(1, 3) === 'he' ? 'gr' : this.props.location.pathname.substring(1, 3)) + "/help#_My_Comments"}> <I18n t="header.Mycomments" /></a>
							</p>
						</main>
					</div>
					<div className="govgr-accordion">
						<details className="govgr-accordion__section" open>
							<summary className="govgr-accordion__section-summary">
								<h2 className="govgr-accordion__section-heading"><span className="govgr-accordion__section-button"><a name="_Είσοδος" href={"/" + (this.props.location.pathname.substring(1, 3) === 'he' ? 'gr' : this.props.location.pathname.substring(1, 3)) + "/help#_Login"}><I18n t="home.Login" /></a></span></h2>
							</summary>
							<div className="govgr-accordion__section-content">
								<p className="govgr-body">
									Για να εισέλθετε στη διαβούλευση θα πρέπει να κάνετε πρώτα Εγγραφή (Sign up) στο σύστημα και στη συνέχεια Είσοδο (Login).
								</p>
							</div>
						</details>
						<details className="govgr-accordion__section" open>
							<summary className="govgr-accordion__section-summary">
								<h2 className="govgr-accordion__section-heading"><span className="govgr-accordion__section-button"><a name="_Citizen_Board" href={"/" + (this.props.location.pathname.substring(1, 3) === 'he' ? 'gr' : this.props.location.pathname.substring(1, 3)) + "/help#_Citizen_Board"}><I18n t="header.CitizenBoard" /></a></span></h2>
							</summary>
							<div className="govgr-accordion__section-content">
								<p className="govgr-body">
									Η πρώτη σελίδα που θα εμφανιστεί, περιέχει τις βασικές πληροφορίες
									(διεύθυνση, ενασχόληση, email και με ποια ιδιότητα βρίσκεστε στη
									διαβούλευση).
								</p>
								<p className="govgr-body">
									Στην σελίδα Citizen Board βλέπετε τα θέματα που βρίσκονται προς
									διαβούλευση.
								</p>
								<p className="govgr-body">
									Πατώντας σε ένα από τα Θέματα αυτά, εισέρχεστε στις πληροφορίες του θέματος
									αλλά και στις προτάσεις που έχουν παραθέσει κάποιοι πολίτες πάνω στο
									συγκεκριμένο θέμα.
								</p>
								<p className="govgr-body">
									<img alt="loading-img"
										border="0"
										width="552"
										height="159"
										src="/assets/img/clip_image002.png"
									/>
								</p>
								<p className="govgr-body">
									Εσείς με τη επιλογή «Create Proposal» μπορείτε να παραθέσετε τη δικιά σας
									πρόταση πάνω στο θέμα. Μπαίνοντας γράφετε την πρότασή σας στο “Title of
									Proposal” και πατάτε «Create Prososal» ώστε να δημιουργηθεί.
								</p>
								<p className="govgr-body">
									<img alt="loading-img"
										border="0"
										width="552"
										height="110"
										src="/assets/img/clip_image004.jpg"
									/>
								</p>
								<p className="govgr-body">
									Στο Citizen Board και μπαίνοντας σε μία άλλη πρόταση εκτός της δική σας,
									σας δίνετε η δυνατότητα να παραθέσετε σχόλιο προς την συγκεκριμένη πρόταση.
									Γράφετε το σχόλιό σας στο “Add Comment” και πριν πατήσετε «Create Comment»
									μπορείτε να επιλέξετε αν θέλετε να βλέπουν το σχόλιό σας και οι υπόλοιποι
									χρήστες ή μόνο ο δημιουργός της πρότασης.
								</p>
								<p className="govgr-body">
									<img alt="loading-img"
										border="0"
										width="552"
										height="264"
										src="/assets/img/clip_image006.jpg"
									/>
								</p>
								<p className="govgr-body">
									Κατά τη φάση της ψηφοφορίας της πρότασης μέσα σε ένα θέμα, μπορείτε από το
									Citizen Board, να αξιολογήσετε αν σας αρέσει η κάθε πρόταση με την επιλογή
									«LIKE», αν δεν σας αρέσει με την επιλογή «DISLIKE» και αν σας φαίνεται
									ουδέτερη με την επιλογή «NEUTRAL». Το πινακάκι που εμφανίζεται, σας
									ενημερώνει πόσα ακόμη Likes, Dislikes και Neutral έχετε διαθέσιμα.
								</p>
								<p className="govgr-body">
									<img alt="loading-img"
										border="0"
										width="552"
										height="167"
										src="/assets/img/clip_image008.jpg"
									/>
								</p>
							</div>
						</details>
						<details className="govgr-accordion__section" open>
							<summary className="govgr-accordion__section-summary">
								<h2 className="govgr-accordion__section-heading"><span className="govgr-accordion__section-button"><a name="_Proposer_Board" href={"/" + (this.props.location.pathname.substring(1, 3) === 'he' ? 'gr' : this.props.location.pathname.substring(1, 3)) + "/help#_Proposer_Board"}><I18n t="header.ProposerBoard" /></a></span></h2>
							</summary>
							<div className="govgr-accordion__section-content">
								<p className="govgr-body">
									Όταν πλέον έχετε δημιουργήσει τη δικιά σας πρόταση, μπορείτε μπαίνοντας
									μέσω του Proposer Board να κοινοποιήσετε την πρότασή σας στα μέσα
									κοινωνικής δικτύωσης ώστε να υπάρξουν υποστηρικτές της πρότασής σας.
								</p>
								<p className="govgr-body">
									Στην ίδια σελίδα όμως μπορείτε να κάνετε και επεξεργασία της πρότασης που
									έχετε παραθέσει.
								</p>
								<p className="govgr-body">
									<img alt="loading-img"
										border="0"
										width="553"
										height="115"
										src="/assets/img/clip_image010.jpg"
									/>
								</p>
								<p className="govgr-body">
									Όταν όμως διαπιστώσετε πως κάποιος έχει κάνει κάποιο σχόλιο στην δικιάς σας
									πρόταση και εσείς θέλετε να το συμπεριλάβετε στην πρότασή σας, τότε όπως
									είπαμε και πιο πάνω, κατά την επεξεργασία της πρότασης μέσω του Proposer
									Board, μπορείτε να επιλέξετε και το αντίστοιχο σχετικό σχόλιο που θα
									συμπεριλάβετε μετά την επεξεργασία.
								</p>
								<p className="govgr-body">
									<img alt="loading-img"
										border="0"
										width="552"
										height="198"
										src="/assets/img/clip_image012.jpg"
									/>
								</p>
								<p className="govgr-body">
									Αν στη συνέχεια της διαβούλευσης, διαπιστώσετε πως υπάρχει κάποια πρόταση
									που θα μπορούσε να συγχωνευτεί με τη δική σας, τότε μέσω του Proposer
									Board, μπορείτε να κάνετε αίτημα “Merge Proposals” στον αντίστοιχο χρήστη
									με τη συγκεκριμένη πρόταση.
								</p>
								<p className="govgr-body">
									<img alt="loading-img"
										border="0"
										width="552"
										height="151"
										src="/assets/img/clip_image014.jpg"
									/>
								</p>
								<p className="govgr-body">
									<img alt="loading-img"
										border="0"
										width="553"
										height="240"
										src="/assets/img/clip_image016.jpg"
									/>
								</p>
								<p className="govgr-body">
									Αν κάποιος όμως έχει κάνει σε εσάς αίτημα για “Merge Proposals”, τότε εσείς
									μέσω του Proposer Board μπορείτε να δείτε αυτά τα αιτήματα από το «Merge
									Requests» και να επιλέξετε με το «Accept Merge request» αν επιθυμείτε να
									γίνει η συγχώνευση, αλλιώς με το «Reject Merge request» την απορρίπτετε.
								</p>
								<p className="govgr-body">
									<img alt="loading-img"
										border="0"
										width="553"
										height="140"
										src="/assets/img/clip_image018.jpg"
									/>
								</p>
								<p className="govgr-body">
									<img alt="loading-img"
										border="0"
										width="553"
										height="293"
										src="/assets/img/clip_image020.jpg"
									/>
								</p>
							</div>
						</details>
						<details className="govgr-accordion__section" open>
							<summary className="govgr-accordion__section-summary">
								<h2 className="govgr-accordion__section-heading"><span className="govgr-accordion__section-button"><a name="_My_Comments" href={"/" + (this.props.location.pathname.substring(1, 3) === 'he' ? 'gr' : this.props.location.pathname.substring(1, 3)) + "/help#_My_Comments"}><I18n t="header.Mycomments" /></a></span></h2>
							</summary>
							<div className="govgr-accordion__section-content">
								<p className="govgr-body">
									Αν από την άλλη κάποιος άλλος χρήστης έχει συμπεριλάβει στη δικιά του
									πρόταση, δικό σας σχόλιο, τότε μέσω του My Comments, θα σας εμφανίζει δίπλα
									από το σχόλιό σας την επιλογή «Check!».
								</p>
								<p className="govgr-body">
									<img alt="loading-img"
										border="0"
										width="552"
										height="134"
										src="/assets/img/clip_image022.jpg"
									/>
								</p>
								<p className="govgr-body">
									Eσείς πρέπει να ελέγξετε αν όντως ύστερα από την επεξεργασία της πρότασής
									του ο χρήστης έχει συμπεριλάβει το σχόλιό σας ώστε να κάνετε «VERIFY»,
									αλλιώς «DO NOT VERIFY».
								</p>
								<p className="govgr-body">
									<img alt="loading-img"
										border="0"
										width="553"
										height="185"
										src="/assets/img/clip_image024.jpg"
									/>
								</p>
							</div>
						</details>
					</div>
				</div>
				<Footer />
			</>
		);
	}
}
