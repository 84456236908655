import axios from 'axios';
import authHeader from './auth-header';

const API_ADMIN_URL = 'https://www.podsweb-backend.dynacomp2.eu/api/pods/admin/proposal/';

class AdminProposalCommentService {

	getProposal(proposal) {
		return axios.get(API_ADMIN_URL, {
			headers: authHeader(),
			params: {
				proposal: proposal
			},
		}
		);
	}

	getProposalProposer(proposal) {
		return axios.get(API_ADMIN_URL + "proposer", {
			headers: authHeader(),
			params: {
				proposal: proposal
			},
		}
		);
	}

	getProposalComments(proposal) {
		return axios.get(API_ADMIN_URL + "comments", {
			headers: authHeader(),
			params: {
				proposal: proposal
			},
		}
		);
	}

	getProposalHistory(proposal) {
		return axios.get(API_ADMIN_URL + "history", {
			headers: authHeader(),
			params: {
				proposal: proposal
			},
		}
		);
	}

}

export default new AdminProposalCommentService();
